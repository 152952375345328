import DemoRequested from '@/components/modals/GetStartedModal/DemoRequested';
import InitialForm from '@/components/modals/GetStartedModal/InitialForm';
import TrialSignup from '@/components/modals/GetStartedModal/TrialSignup';
import Modal from '@/components/modals/Modal';
import { GTM_EVENTS } from '@/constants/gtm';
import { OPTIMIZELY_PAGES } from '@/constants/optimizely';
import { GET_STARTED_MODAL_HASH } from '@/constants/routes';
import { GlobalContext } from '@/context';
import { ChiliPiperScript } from '@/lib/chili-piper';
import { createLogger } from '@/lib/debug';
import gtm from '@/lib/gtm';
import optimizely from '@/lib/optimizely';
import { COLORS } from '@/styles/color';
import { marginY, mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { remCalc } from '@/styles/typography/utils';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const logger = createLogger('get started modal');

// #region - Components

const StyledModal = styled(Modal)<{ accentColor: string }>`
  --modal-max-width: ${remCalc(340)};
  --modal-background-color: linear-gradient(192.98deg, ${(props) => props.accentColor} 14.67%, #ffffff 115.95%);

  ${mediaBreakpointUp('desktop')} {
    --modal-max-width: none;
    --modal-width: ${remCalc(800)};
    --modal-height: ${remCalc(736)};
  }

  ${mediaBreakpointUp('desktopWide')} {
    --modal-width: ${remCalc(1140)};
  }
`;

export const EyebrowTitle = styled.h2`
  ${TEXT_SIZES[16]};
  ${marginY(14, 16)};

  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  text-align: center;

  ${mediaBreakpointUp('desktop')} {
    ${TEXT_SIZES[24]};
    margin-bottom: ${remCalc(32)};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// #endregion - Components

// #region - CSS

// #endregion - CSS

interface GetStartedModalProps {
  onClose?: () => void;
}

const GetStartedModal: FC<GetStartedModalProps> = ({ onClose }) => {
  const router = useRouter();
  const { toggleGetStartedModal, getStartedModalFlow } = useContext(GlobalContext);
  const [formValues, setFormValues] = useState({});

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [isTrial, setIsTrial] = useState<boolean>(false);
  const [isPayingCustomer, setIsPayingCustomer] = useState<boolean>(false);

  const [eyebrowTitle, accentColor] = useMemo(() => {
    switch (getStartedModalFlow) {
      case 'forceDemo':
        return ['Talk to Sales', COLORS.BLUE.HIGHLIGHT];
      case 'forceTrial':
        return ['Start a trial', COLORS.BLUE.HIGHLIGHT];
      default:
        return ['Get started', COLORS.ORANGE.HIGHLIGHT];
    }
  }, [getStartedModalFlow]);

  useEffect(() => {
    gtm.event(GTM_EVENTS.GET_STARTED_MODAL_OPENED);

    const deactivate = optimizely.activatePage(OPTIMIZELY_PAGES.ANY_GET_STARTED_MODAL);

    const [currentPath, currentHash] = router.asPath.split('#');

    if (currentHash !== GET_STARTED_MODAL_HASH) {
      /* Weird hack because useRouterEvents doesn't pick this change up */
      setTimeout(() => {
        try {
          router.push(`${currentPath}#${GET_STARTED_MODAL_HASH}`, undefined, {
            shallow: true,
            scroll: false,
          });
        } catch (e) {
          // Workaround for https://github.com/vercel/next.js/issues/37362
          if (!e.cancelled) throw e;
        }
      }, 0);
    }

    return () => {
      deactivate();

      gtm.event(GTM_EVENTS.GET_STARTED_MODAL_CLOSED);

      const [pathName] = currentPath.split('?');

      if (pathName === window.location.pathname) {
        logger.log('On the same path as when opened, removing hash...');

        try {
          /* Weird hack because useRouterEvents doesn't pick this change up */
          setTimeout(() => {
            router.push(currentPath, undefined, {
              shallow: true,
              scroll: false,
            });
          }, 0);
        } catch (e) {
          // Workaround for https://github.com/vercel/next.js/issues/37362
          if (!e.cancelled) throw e;
        }
      } else {
        logger.log('Not on the same path as when opened, not attempting to remove URL hash');
      }
    };
  }, []);

  return (
    <>
      <StyledModal
        name="Get Started Modal"
        id="get-started-modal"
        accentColor={accentColor}
        onClose={() => {
          toggleGetStartedModal(false);
          if (onClose) onClose();
        }}
      >
        <FlexWrapper>
          {!isFormSubmitted ? (
            <>
              <EyebrowTitle>{eyebrowTitle}</EyebrowTitle>
              <InitialForm
                onFormSubmit={(values, isTrial, _isPayingCustomer) => {
                  setFormValues(values);
                  setIsTrial(isTrial);
                  setIsFormSubmitted(true);
                  setIsPayingCustomer(_isPayingCustomer);
                }}
              />
            </>
          ) : null}

          {/* Demo requested */}
          {isFormSubmitted && !isTrial ? (
            <DemoRequested formValues={formValues} isPayingCustomer={isPayingCustomer} />
          ) : null}

          {/* Trial signup */}
          {isFormSubmitted && isTrial ? <TrialSignup formValues={formValues} eyebrowTitle={eyebrowTitle} /> : null}
        </FlexWrapper>
      </StyledModal>

      {/* Chili Piper */}
      {ChiliPiperScript}
    </>
  );
};

export default GetStartedModal;
