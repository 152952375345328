import { HubspotFormIDs } from '@/constants/hubspot';
import { AssetFragment, LinkFragment, Maybe } from '@/graphql';
import { InternalURL, isInternalUrl } from '@/lib/urls';
import { ImageProps } from 'next/image';
import { SetNonNullable, SetRequired } from 'type-fest';
import logging from './logging';

const getContentType = (__typename: string) => {
  switch (__typename) {
    case 'customerStories_customerStory_Entry':
      return 'Customer Story';
    case 'blog_blogArticle_Entry':
      return 'Article';
    case 'resources_resourceWebinar_Entry':
      return 'Webinar';
    case 'resources_resourceAsset_Entry':
    case 'resources_resourceExternal_Entry':
      return 'Resource';
    default:
      return 'Content';
  }
};

const getResourceFormId = (resourceType: string) => {
  switch (resourceType.toLowerCase()) {
    case 'guide':
      return HubspotFormIDs.ResourceGuide;
    case 'worksheet':
      return HubspotFormIDs.ResourceWorksheet;
    case 'checklist':
      return HubspotFormIDs.ResourceChecklist;
    case 'research':
      return HubspotFormIDs.ResourceWhitepaper;
    case 'ebook':
    default:
      return HubspotFormIDs.ResourceEbook;
  }
};

const CRAFT_REFERENCE_PATTERN = /\#[\w-]+\:[0-9]+\@[0-9]+/i;

const parseUrlFromCraft = (href: string | null | undefined): string => {
  if (!href) return '#';

  try {
    const url = new InternalURL(href);

    /* Remove the port */
    url.port = '';

    if (url.hash) {
      /* Check for references from Craft and remove them */
      url.hash = url.hash.toLowerCase().replace(CRAFT_REFERENCE_PATTERN, '');
    }

    if (isInternalUrl(url)) {
      /* This is an internal URL, so we can strip off the domain */
      const internalUrl = `${url.pathname}${url.search}${url.hash}`;

      if (internalUrl.startsWith('/#')) {
        return url.hash;
      }

      if (internalUrl === '/') {
        return '/';
      }

      return internalUrl.replace(/\/+?$/gi, '');
    }

    return url.toString();
  } catch (e) {
    // This is an invalid URL
    logging.captureException(e, (event) => {
      event.severity = 'warning';
      event.context = 'lib/craft-utils';
      event.addMetadata('url', { href });
    });

    return href;
  }
};

const isAsset = (asset: AssetFragment | null): asset is AssetFragment => {
  return asset?.url !== '';
};

const getImagePropsFromAsset = (source: AssetFragment | AssetFragment[]): ImageProps => {
  const asset = Array.isArray(source) ? source[0] : source;

  return {
    src: asset.url ?? '',
    width: asset.width ?? 0,
    height: asset.height ?? 0,
    alt: ('altText' in asset ? asset.altText : '') ?? '',
  };
};

function getSingleLink<T extends LinkFragment>(
  link: Maybe<T>[] | null | undefined,
): SetRequired<SetNonNullable<T, 'url' | 'text'>, 'url' | 'text'> | null {
  if (!link || !Array.isArray(link) || link.length === 0 || link[0] === null) return null;

  const [singleLink] = link;

  if (!singleLink.url) return null;

  singleLink.text = singleLink.text ?? '';

  return singleLink as SetRequired<SetNonNullable<T, 'url' | 'text'>, 'url' | 'text'>;
}

export { getContentType, parseUrlFromCraft, isAsset, getImagePropsFromAsset, getResourceFormId, getSingleLink };
