import { COLORS } from '@/styles/color';
import { remCalc } from '@/styles/typography/utils';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const INPUT_SIZE = 48;
const INPUT_MARGIN = 8;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
`;

const CodeInputCell = styled.div`
  width: ${remCalc(INPUT_SIZE)};
  height: ${remCalc(INPUT_SIZE)};
  border: 2px solid ${COLORS.BLACK};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  font-size: ${remCalc(24)};

  border-radius: ${remCalc(5)};
  margin-right: ${remCalc(INPUT_MARGIN)};

  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }
`;

interface CodeInputWrapperProps {
  focused: boolean;
  selectedIndex: number;
  filled: boolean;
  disabled?: boolean;
}

const CodeInputWrapper = styled.div<CodeInputWrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 0px;
  width: 100%;

  ${(props) =>
    !props.filled &&
    props.focused &&
    css`
      ${CodeInputCell}:nth-child(${props.selectedIndex + 1}) {
        border-color: ${COLORS.BLUE.BASE};
      }
    `}

  ${(props) =>
    props.filled &&
    props.focused &&
    css`
      ${CodeInputCell} {
        border-color: ${COLORS.BLUE.BASE};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      ${CodeInputCell} {
        border-color: ${COLORS.GRAY.BASE};
        color: ${COLORS.GRAY.BASE};
      }
    `}
`;

const HiddenCodeInput = styled.input`
  position: absolute;
  border: none;
  font-size: ${remCalc(32)};
  text-align: center;
  background-color: transparent;
  outline: none;
  opacity: 0;
  width: 0;
  height: 0;
  appearance: none;
`;

interface CodeInputProps extends PropsWithClassName {
  length: number;
  code: string;
  onChange: (code: string) => void;
  disabled?: boolean;
}

const CodeInput: FC<CodeInputProps> = ({ code, length, onChange, disabled, className }) => {
  const [validationCode, setValidationCode] = useState('');
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const onValidationCodeInputClick = () => ref.current?.focus();
  const onValidationCodeInputFocus = () => setFocused(true);
  const onValidationCodeInputBlur = () => setFocused(false);

  const onValidationCodeInputKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return;

    if (e.key === 'Backspace') {
      setValidationCode(validationCode.slice(0, validationCode.length - 1));
    }
  };

  const onValidationCodeInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const { value } = e.target as HTMLInputElement;
    const cleanValue = value.replace(/[^0-9]/gim, '');

    if (validationCode.length <= length) {
      setValidationCode((validationCode + cleanValue).slice(0, length));
    }
  };

  const selectedIndex = useMemo(() => {
    return validationCode.length < length ? validationCode.length : length - 1;
  }, [validationCode, length]);

  useEffect(() => {
    onChange(validationCode);
  }, [validationCode]);

  useEffect(() => {
    setValidationCode(code);
  }, [code]);

  return (
    <Wrapper className={className}>
      <CodeInputWrapper
        onClick={onValidationCodeInputClick}
        selectedIndex={selectedIndex}
        focused={focused}
        filled={validationCode.length === length}
        disabled={disabled}
      >
        {/* Create input cells */}
        {[...Array(length)].map((e, i) => (
          <CodeInputCell key={`cell-${i}`}>{validationCode.charAt(i)}</CodeInputCell>
        ))}

        <HiddenCodeInput
          ref={ref}
          id="hidden-code-input"
          value=""
          onFocus={onValidationCodeInputFocus}
          onBlur={onValidationCodeInputBlur}
          onChange={onValidationCodeInputChange}
          onKeyUp={onValidationCodeInputKeyUp}
        />
      </CodeInputWrapper>
    </Wrapper>
  );
};

export default CodeInput;
