import PrimaryInnerGlowButton from '@/components/buttons/primary';
import Checkbox from '@/components/forms/Checkbox';
import CodeInput from '@/components/forms/CodeInput';
import InputField from '@/components/forms/InputField';
import { EyebrowTitle } from '@/components/modals/GetStartedModal/Modal';
import { GTM_EVENTS, GTM_VARIABLES } from '@/constants/gtm';
import { HubspotFieldLabels, HubspotFieldNames, HubspotFormIDs } from '@/constants/hubspot';
import { OPTIMIZELY_EVENTS, OPTIMIZELY_PAGES } from '@/constants/optimizely';
import { PRIVACY_NOTICE, SAAS_SERVICES_AGREEMENT } from '@/constants/routes';
import { FRONT_LEGAL_NAME } from '@/constants/strings';
import frontBackend from '@/lib/api/front-backend';
import { SignupErrorResponse, SignupRequest } from '@/lib/api/front-backend/signup';
import { getAppDomain } from '@/lib/api/front-backend/utils';
import attribution from '@/lib/attribution';
import gtm from '@/lib/gtm';
import hubspot from '@/lib/hubspot';
import logging from '@/lib/logging';
import optimizely from '@/lib/optimizely';
import { validatePassword } from '@/lib/validation';
import { COLORS } from '@/styles/color';
import { mediaBreakpointUp, paddingY } from '@/styles/layout/utils';
import { FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

// #region - Components

const StepHeadline = styled.h3`
  ${TEXT_SIZES[24]};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  text-align: center;

  ${mediaBreakpointUp('desktop')} {
    ${TEXT_SIZES[32]};
  }
`;

const FormGrid = styled.form`
  display: flex;
  flex-direction: column;

  column-gap: ${remCalc(40)};
  row-gap: ${remCalc(20)};

  width: 100%;
  max-width: ${remCalc(500)};
  margin: 0 auto;

  ${mediaBreakpointUp('desktop')} {
    margin-top: ${remCalc(-30)};
  }

  ${PrimaryInnerGlowButton} {
    align-self: flex-start;
  }
`;

const StepSubhead = styled.p`
  font-size: ${remCalc(16)};
  line-height: ${remCalc(24)};
  text-align: center;
  margin-top: ${remCalc(8)};
  margin-bottom: ${remCalc(50)};
`;

const StepFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${paddingY(20)}

  ${customTextSize(14, 24)}
  text-align: center;

  ${mediaBreakpointUp('desktop')} {
    margin: auto 0 0 0;
  }

  a {
    color: ${COLORS.RED.LOWLIGHT};
    cursor: pointer;
  }
`;

const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${remCalc(10)};

  p {
    margin-left: ${remCalc(8)};
    cursor: pointer;
    text-align: left;
  }

  > :first-child {
    flex-shrink: 0;
    margin-right: ${remCalc(2)};
  }
`;

interface SubmissionErrorTextProps {
  alignCenter?: boolean;
}

const SubmissionErrorText = styled.p<SubmissionErrorTextProps>`
  ${TEXT_SIZES[13]};
  color: ${COLORS.RED.LOWLIGHT};
  text-align: ${(props) => (props.alignCenter ? 'center' : 'left')};
`;

const CodeInputInstructions = styled.p`
  text-align: center;
  margin-bottom: ${remCalc(16)};
`;

const StyledCodeInput = styled(CodeInput)`
  margin-bottom: ${remCalc(16)};
`;

const VerifyButton = styled(PrimaryInnerGlowButton)`
  display: block;
  margin: ${remCalc(16)} auto 0;
`;

// #endregion - Components

const onSaaSServicesAgreementClick = () => {
  gtm.track('click', {
    element_type: 'link',
    location: 'Get Started Modal',
    element_label: 'Trial Signup - SaaS Services Agreement',
  });
};

const onPrivacyNoticeClick = () => {
  gtm.track('click', {
    element_type: 'link',
    location: 'Get Started Modal',
    element_label: 'Trial Signup - Privacy Notice',
  });
};

interface TrialSignupProps {
  formValues: { [key: string]: string };
  eyebrowTitle: string;
}

const TrialSignup: FC<TrialSignupProps> = ({ formValues, eyebrowTitle }) => {
  const [marketingEmailOptOut, setMarketingEmailOptOut] = useState<boolean>(false);
  const [passwordTouched, setPasswordTouched] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [showCodeEntry, setShowCodeEntry] = useState<boolean>(false);
  const [validationCode, setValidationCode] = useState<string>('');
  const [submissionError, setSubmissionError] = useState<string>('');
  const [submittingValidation, setSubmittingValidation] = useState<boolean>(false);
  const [submittingCode, setSubmittingCode] = useState<boolean>(false);

  const canSubmit = useMemo(() => validatePassword(password), [password]);

  const onSignup = useCallback(
    (e) => {
      e.preventDefault();

      if (!canSubmit) {
        setPasswordTouched(true);

        return;
      }

      gtm.event(GTM_EVENTS.GET_STARTED_TRIAL_SUBMIT);

      gtm.track('submit_form', {
        location: 'Get Started Modal',
        form_name: 'Trial Signup',
      });

      optimizely.event(OPTIMIZELY_EVENTS.GET_STARTED_TRIAL_SUBMIT);

      sendValidationCode();
    },
    [canSubmit],
  );

  const signupPayload = useMemo<Omit<SignupRequest, 'code'>>(
    () => ({
      affiliate: attribution.getAffiliateFromCookie(),
      company: formValues[HubspotFieldNames.CompanyName],
      email: formValues[HubspotFieldNames.Email] ?? '',
      family_name: formValues[HubspotFieldNames.LastName], // Last name
      given_name: formValues[HubspotFieldNames.FirstName], // First name
      industry: formValues[HubspotFieldNames.Industry],
      origin: window?.location?.href,
      password,
      phone: formValues[HubspotFieldNames.Phone] ?? '',
      size: formValues[HubspotFieldNames.CompanySize],
    }),
    [formValues, password],
  );

  const sendValidationCode = useCallback(() => {
    if (submittingValidation) return;

    setSubmittingValidation(true);
    setValidationCode('');
    setSubmissionError('');

    frontBackend
      .validateEmail(signupPayload)
      .then(() => {
        setShowCodeEntry(true);
      })
      .catch(() => {
        setSubmissionError('An unexpected error occured, please refresh and try again');
      })
      .finally(() => {
        setSubmittingValidation(false);
      });
  }, [formValues, password, submittingValidation]);

  const checkValidationCode = useCallback(() => {
    if (submittingCode) return;

    setSubmittingCode(true);
    setSubmissionError('');

    frontBackend
      .signup({
        ...signupPayload,
        code: validationCode,
      })
      .then(async ({ data }) => {
        // TODO re-add autojoin logic

        const signupEventPayload = {
          [GTM_VARIABLES.USER_EMAIL]: signupPayload.email,
          [GTM_VARIABLES.USER_FIRST_NAME]: signupPayload.given_name,
          [GTM_VARIABLES.USER_LAST_NAME]: signupPayload.family_name,
          affiliate: signupPayload.affiliate || '',
          company: signupPayload.company,
          gclid: attribution.getAttributionFromCookie().gclid,
          industry: signupPayload.industry,
          label: window.btoa(unescape(encodeURIComponent(data.first_admin_id ?? data.id ?? ''))),
          phone: signupPayload.phone?.replace(/[- )(]/g, ''),
          size: signupPayload.size,
          teammate_id: data.first_admin_id,
        };

        gtm.trackTrialSignup(signupEventPayload);

        gtm.event(GTM_EVENTS.GET_STARTED_TRIAL_SIGNUP, signupEventPayload);

        gtm.track('submit_form', {
          location: 'Get Started Modal',
          form_name: 'Trial Email Code',
        });

        optimizely.event(OPTIMIZELY_EVENTS.GET_STARTED_TRIAL_SIGNUP);
        optimizely.event(OPTIMIZELY_EVENTS.GET_STARTED_CONVERSION);
        optimizely.event(OPTIMIZELY_EVENTS.TRIAL_SIGNUP);

        hubspot
          .submitForm(HubspotFormIDs.TrialSignup, {
            [HubspotFieldNames.Affiliate]: signupPayload.affiliate,
            [HubspotFieldNames.CompanyName]: signupPayload.company,
            [HubspotFieldNames.CompanySize]: signupPayload.size,
            [HubspotFieldNames.ContentName]: 'Trial Signup',
            [HubspotFieldNames.Email]: signupPayload.email,
            [HubspotFieldNames.FirstName]: signupPayload.given_name,
            [HubspotFieldNames.Industry]: signupPayload.industry,
            [HubspotFieldNames.JobTitle]: formValues[HubspotFieldNames.JobTitle],
            [HubspotFieldNames.LastName]: signupPayload.family_name,
            [HubspotFieldNames.Phone]: signupPayload.phone,
            [HubspotFieldNames.MarketingEmailOptOut]: String(marketingEmailOptOut),
          })
          .catch((e) => {
            logging.captureException(e);
          })
          .finally(() => {
            setTimeout(() => {
              window.location.href = data.first_login_url ?? getAppDomain();
            }, 250);
          });
      })
      .catch((error: AxiosError<SignupErrorResponse>) => {
        setSubmittingCode(false);
        setValidationCode('');

        if (error.response?.data?.code === 'bad_validation_code') {
          setSubmissionError('Incorrect validation code');
        } else if (error.response?.status === 429) {
          setSubmissionError('Too many attempts, please try again later');
        } else {
          setSubmissionError('An unexpected error occured, please refresh and try again');
        }
      });
  }, [submittingCode, signupPayload, validationCode, marketingEmailOptOut]);

  useEffect(() => {
    if (validationCode.length === 6) checkValidationCode();
  }, [validationCode, submissionError]);

  useEffect(() => {
    const deactivate = optimizely.activatePage(OPTIMIZELY_PAGES.GET_STARTED_TRIAL_SIGNUP);

    return () => deactivate();
  }, []);

  if (!showCodeEntry) {
    return (
      <>
        <EyebrowTitle>{eyebrowTitle}</EyebrowTitle>
        <StepHeadline>Thank you so much, {formValues[HubspotFieldNames.FirstName]}!</StepHeadline>
        <StepSubhead>
          Let’s get you started on a 14-day free trial. Enter a password to finish setting up your account.
        </StepSubhead>

        <FormGrid onSubmit={onSignup}>
          <InputField
            type="email"
            name={HubspotFieldNames.Email}
            label={HubspotFieldLabels.Email}
            placeholder="you@company.com"
            value={formValues[HubspotFieldNames.Email]}
            invalid={false}
            translucent
            disabled
          />

          <InputField
            type="password"
            name="password"
            label="Password"
            value={password}
            onValueChange={(value) => setPassword(value)}
            onBlur={() => setPasswordTouched(true)}
            invalid={passwordTouched && !canSubmit}
            errorMessage="8 characters minimum, at least 1 uppercase letter and 1 number"
            translucent
          />

          <PrimaryInnerGlowButton type="submit" onClick={onSignup} disabled={submittingValidation}>
            {submittingValidation ? 'Submitting...' : 'Sign up'}
          </PrimaryInnerGlowButton>

          {!!submissionError && <SubmissionErrorText>{submissionError}</SubmissionErrorText>}
        </FormGrid>

        <StepFooter>
          <p>
            By signing up, you agree to {FRONT_LEGAL_NAME}&rsquo;s{' '}
            <a href={SAAS_SERVICES_AGREEMENT} target="_blank" onClick={onSaaSServicesAgreementClick}>
              SaaS Services Agreement
            </a>{' '}
            and{' '}
            <a href={PRIVACY_NOTICE} target="_blank" onClick={onPrivacyNoticeClick}>
              Privacy Notice
            </a>
            .
          </p>

          <CheckboxRow>
            <Checkbox checked={marketingEmailOptOut} onToggle={setMarketingEmailOptOut} />
            <p onClick={() => setMarketingEmailOptOut(!marketingEmailOptOut)}>
              I <strong>do not</strong> want to receive marketing communications from Front.
            </p>
          </CheckboxRow>
        </StepFooter>
      </>
    );
  }

  return (
    <>
      <EyebrowTitle>{eyebrowTitle}</EyebrowTitle>
      <StepHeadline>Please verify your email 📨</StepHeadline>
      <StepSubhead>
        We just sent a six-digit confirmation code to {formValues[HubspotFieldNames.Email]}. It will expire in 30
        minutes, enter it soon!
        <br />
        Keep this window open while checking for your code.
      </StepSubhead>

      <CodeInputInstructions>Enter your code below:</CodeInputInstructions>

      <StyledCodeInput length={6} code={validationCode} onChange={setValidationCode} disabled={submittingCode} />

      {!!submissionError && <SubmissionErrorText alignCenter>{submissionError}</SubmissionErrorText>}

      <VerifyButton
        as="button"
        disabled={submittingCode}
        onClick={() => {
          checkValidationCode();
        }}
      >
        {submittingCode ? 'Verifying...' : 'Verify'}
      </VerifyButton>

      <StepFooter>
        <p>
          Didn&rsquo;t receive an email? <a onClick={sendValidationCode}>Resend</a>
        </p>
      </StepFooter>
    </>
  );
};

export default TrialSignup;
