import Image from "next/image";

const HEADER_PROPS = {
  /*
   *promoBanner: (
   *  <div className="bg-citron-100 hover:bg-citron-500 duration-200 transition-colors ease-in-out-quad text-citron-900 p-1 md:p-[14px] text-center text-12/16 md:text-14/20 relative">
   *    <a href="https://front.com/frontlines" className="link-cover">
   *      Frontlines 2024: See game-changing new Front features and learn how to win in the future of customer service &#8594;
   *    </a>
   *  </div>
   *),
   */
  desktopMenu: [
    {
      type: 'drawer' as const,
      title: 'Product',
      sections: [
        {
          image: (
            <a href="https://front.com/product" className="w-full block">
              <Image src="/images/front-product-overview-nav-v2.png" width={330} height={195} alt="Front Product Interface Screenshot" />
            </a>
          ),
          featuredLinks: [
            { title: 'Front Overview', href: '/product' },
            { title: 'Product Tour', href: '/product-tour' },
          ],
        },
        {
          title: 'Key Features',
          linkColumns: 2 as const,
          links: [
            {
              title: 'Omnichannel',
              subtitle: 'Every channel in one inbox',
              icon: <Image src="/images/icon-feature-omnichannel.svg" width={14} height={14} alt="Feature Icon Omnichannel" />,
              href: '/product/omnichannel-inbox',
            },
            {
              title: 'AI',
              subtitle: 'Resolve faster than ever',
              icon: <Image src="/images/icon-feature-ai-front-intelligence.svg" width={14} height={14} alt="Feature Icon AI" />,
              href: '/product/ai',
            },
            {
              title: 'Collaboration',
              subtitle: 'Work faster, together',
              icon: <Image src="/images/icon-feature-collaboration.svg" width={14} height={14} alt="Feature Icon Collaboration" />,
              href: '/product/collaboration',
            },
            {
              title: 'Live chat',
              subtitle: 'Real-time support and bots',
              icon: <Image src="/images/icon-feature-livechat.svg" width={14} height={14} alt="Feature Icon Live Chat Bots" />,
              href: '/product/live-chat',
            },
            {
              title: 'Ticketing',
              subtitle: 'Track support requests',
              icon: <Image src="/images/icon-feature-ticketing.svg" width={14} height={14} alt="Feature Icon Ticketing" />,
              href: '/product/ticketing',
              tag: 'New',
            },
            {
              title: 'Knowledge base',
              subtitle: 'Deflect support questions',
              icon: <Image src="/images/icon-feature-knowledgebase.svg" width={14} height={14} alt="Feature Icon Knowledge Base" />,
              href: '/product/knowledge-base',
            },
            {
              title: 'Analytics',
              subtitle: 'Surface key customer insights',
              icon: <Image src="/images/icon-feature-analytics.svg" width={14} height={14} alt="Feature Icon Analytics" />,
              href: '/product/analytics',
            },
            {
              title: 'Workflow automation',
              subtitle: 'Organize, route, and resolve',
              icon: <Image src="/images/icon-feature-workflow-automation.svg" width={14} height={14} alt="Feature Icon Workflow Automation" />,
              href: '/product/workflows',
            },
            {
              title: 'Support Report',
              subtitle: 'Real-time service metrics',
              icon: <Image src="/images/icon-feature-support-report.svg" width={14} height={14} alt="Feature Icon Support Report" />,
              href: '/product/support-report',
              tag: 'New',
            },
            {
              title: 'Integrations & API',
              subtitle: 'Connect to other systems',
              icon: <Image src="/images/icon-feature-integrations-api.svg" width={14} height={14} alt="Feature Icon Integrations" />,
              href: '/product/integrations-api',
            },
          ],
        },
        /*
         *{
         *title: 'Use Cases',
         *linkColumns: 1,
         *links: [
         *  {
         *    title: 'Help Desk',
         *    subtitle: 'Resolve questions with deflection',
         *    icon: <Image src="/images/icon-placeholder-mobile-menu.svg" width={14} height={14} alt="" />,
         *    href: '#',
         *  },
         *  {
         *    title: 'Email Management',
         *    subtitle: 'Organize, route, and resolve',
         *    icon: <Image src="/images/icon-placeholder-mobile-menu.svg" width={14} height={14} alt="" />,
         *    href: '#',
         *  },
         *  {
         *    title: 'Premier Service',
         *    subtitle: 'Organize, route, and resolve',
         *    icon: <Image src="/images/icon-placeholder-mobile-menu.svg" width={14} height={14} alt="" />,
         *    href: '#',
         *  },
         *],
         *},
         */
      ],
    },
    {
      type: 'drawer' as const,
      title: 'Solutions',
      sections: [
        {
          title: 'Industries',
          linkColumns: 2 as const,
          links: [
            {
              title: 'Technology',
              icon: <Image src="/images/icon-industry-technology.svg" width={14} height={14} alt="Industry Icon Technology" />,
              href: '/industries/b2b-tech',
            },
            {
              title: 'Financial Services',
              icon: <Image src="/images/icon-industry-financial.svg" width={14} height={14} alt="Industry Icon Financial Services" />,
              href: '/industries/financial-services',
            },
            {
              title: 'Logistics',
              icon: <Image src="/images/icon-industry-logistics.svg" width={14} height={14} alt="Industry Icon Logistics" />,
              href: '/industries/logistics',
            },
            {
              title: 'Manufacturing',
              icon: <Image src="/images/icon-industry-manufacturing.svg" width={14} height={14} alt="Industry Icon Manufacturing" />,
              href: '/industries/manufacturing',
            },
            {
              title: 'Professional Services',
              icon: <Image src="/images/icon-industry-professional-services.svg" width={14} height={14} alt="Industry Icon Professional Services" />,
              href: '/industries/professional-services',
            },
            {
              title: 'Travel',
              icon: <Image src="/images/icon-industry-travel.svg" width={14} height={14} alt="Industry Icon Travel" />,
              href: '/industries/travel',
            },
          ],
        },
        {
          title: 'Teams',
          linkColumns: 2 as const,
          links: [
            {
              title: 'Customer Support',
              icon: <Image src="/images/icon-teams-cutomer-service.svg" width={14} height={14} alt="Teams Icon Customer Support" />,
              href: '/teams/customer-service-support',
            },
            {
              title: 'Operations',
              icon: <Image src="/images/icon-teams-operations.svg" width={14} height={14} alt="Teams Icon Operations" />,
              href: '/teams/operations',
            },
            {
              title: 'Inbound Sales',
              icon: <Image src="/images/icon-industry-financial.svg" width={14} height={14} alt="Teams Icon Inbound Sales" />,
              href: '/teams/inbound-sales',
            },
            {
              title: 'Account Management',
              icon: <Image src="/images/icon-industry-professional-services.svg" width={14} height={14} alt="Teams Icon Account Management" />,
              href: '/teams/account-management',
            },
            {
              title: 'Success',
              icon: <Image src="/images/icon-teams-success.svg" width={14} height={14} alt="Teams Icon Customer Success" />,
              href: '/teams/customer-success',
            },
          ],
        },
      ],
      promoBanner: (
        <div className="px-2 py-[12px] rounded-10 bg-blue-100 hover:bg-blue-200 transition-colors duration-200 ease-in-out-quad relative">
          <a href="https://front.com/startups" className="link-cover">
            Front for Startups: Stand out with exceptional support from day 1 &#8594;
          </a>
        </div>
      ),
    },
    {
      type: 'drawer' as const,
      title: 'Resources',
      sections: [
        {
          title: 'Using Front',
          links: [
            { title: 'Academy', href: 'https://academy.front.com/app' },
            { title: 'Community', href: 'https://community.front.com/' },
            { title: 'Help Center', href: 'https://help.front.com/' },
            { title: 'Developer Portal', href: 'https://dev.frontapp.com/docs/welcome' },
          ],
        },
        {
          title: 'Explore',
          linkColumns: 2 as const,
          links: [
            { title: 'Blog', href: '/blog' },
            { title: 'Integrations', href: '/integrations' },
            { title: 'Guides & Webinars', href: '/resources' },
            { title: 'Security', href: '/security' },
            { title: 'Customer Stories', href: '/customer-stories' },
            { title: 'Download App', href: '/download' },
            { title: 'Front\'s Support Report', href: '/support-report' },
            { title: 'Channel Partnerships', href: '/partners/channel' },
          ],
        },
        {
          /*
           * image: <div className="w-full bg-violet-50 aspect-video" />,
           */
          image: (
            <a href="https://front.com/frontlines" className="w-full block">
              <Image
                src="https://front.com/webassets/pages/frontlines/_1200x630_crop_center-center_82_none_ns/front-frontlines-2024-hero.png?mtime=1724781412"
                width={1200}
                height={630}
                alt="Featured Event Frontlines 2024"
              />
            </a>
          ),
          featuredLinks: [{ title: 'Frontlines 2024', href: '/frontlines' }],
        },
      ],
    },
    {
      type: 'link' as const,
      title: 'Pricing',
      href: '/pricing',
    },
  ],

  desktopMenuLinks: [
    {
      type: 'link' as const,
      title: 'Sign in',
      href: 'https://app.frontapp.com/signin',
    },
    {
      type: 'primary' as const,
      title: 'Request a demo',
      href: '',
      action: 'demo' as const,
    },
    {
      type: 'secondary' as const,
      title: 'Try for free',
      href: '',
      action: 'trial' as const,
    },
  ],
  mobileMenu: [
    {
      type: 'drawer' as const,
      title: 'Product',
      sections: [
        {
          title: 'Our product',
          links: [
            {
              title: 'Omnichannel',
              href: '/product/omnichannel-inbox',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-omnichannel.svg"
                  className="block"
                  alt="Product Icon Omnichannel"
                />
              ),
            },
            {
              title: 'AI',
              href: '/product/ai',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-ai-front-intelligence.svg"
                  className="block"
                  alt="Product Icon AI"
                />
              ),
            },
            {
              title: 'Collaboration',
              href: '/product/collaboration',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-collaboration.svg"
                  className="block"
                  alt="Product Icon Collaboration"
                />
              ),
            },
            {
              title: 'Live chat',
              href: '/product/live-chat',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-livechat.svg"
                  className="block"
                  alt="Product Icon Live Chat"
                />
              ),
            },
            {
              title: 'Ticketing',
              href: '/product/ticketing',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-ticketing.svg"
                  className="block"
                  alt="Product Icon Ticketing"
                />
              ),
              tag: 'New',
            },
            {
              title: 'Knowledge base',
              href: '/product/knowledge-base',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-knowledgebase.svg"
                  className="block"
                  alt="Product Icon Knowledge Base"
                />
              ),
            },
            {
              title: 'Analytics',
              href: '/product/analytics',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-analytics.svg"
                  className="block"
                  alt="Product Icon Analytics"
                />
              ),
            },
            {
              title: 'Workflow automation',
              href: '/product/workflows',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-workflow-automation.svg"
                  className="block"
                  alt="Product Icon Workflow Automation"
                />
              ),
            },
            {
              title: 'Support Report',
              href: '/product/support-report',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-support-report.svg"
                  className="block"
                  alt="Product Icon Support Report"
                />
              ),
              tag: 'New',
            },
            {
              title: 'Integrations & API',
              href: '/product/integrations-api',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-feature-integrations-api.svg"
                  className="block"
                  alt="Product Icon Integrations"
                />
              ),
            },
          ],
        },
        /*
         *{
         *  title: 'Use Cases',
         *  links: [
         *    {
         *      title: 'Help Desk',
         *      href: '#',
         *      icon: (
         *        <Image
         *          key="badge-1"
         *          width={24}
         *          height={24}
         *          src="/images/icon-placeholder-mobile-menu.svg"
         *          className="block"
         *          alt="icon"
         *        />
         *      ),
         *    },
         *    {
         *      title: 'Email Management',
         *      href: '#',
         *      icon: (
         *        <Image
         *          key="badge-1"
         *          width={24}
         *          height={24}
         *          src="/images/icon-placeholder-mobile-menu.svg"
         *          className="block"
         *          alt="icon"
         *        />
         *      ),
         *    },
         *    {
         *      title: 'Premier Service',
         *      href: '#',
         *      icon: (
         *        <Image
         *          key="badge-1"
         *          width={24}
         *          height={24}
         *          src="/images/icon-placeholder-mobile-menu.svg"
         *          className="block"
         *          alt="icon"
         *        />
         *      ),
         *    },
         *  ],
         *},
         */
      ],
    },
    {
      type: 'drawer' as const,
      title: 'Solutions',
      sections: [
        {
          title: 'Industries',
          links: [
            {
              title: 'Technology',
              href: '/industries/b2b-tech',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-industry-technology.svg"
                  className="block"
                  alt="Industry Icon Technology"
                />
              ),
            },
            {
              title: 'Financial Services',
              href: '/industries/financial-services',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-industry-financial.svg"
                  className="block"
                  alt="Industry Icon Financial Services"
                />
              ),
            },
            {
              title: 'Logistics',
              href: '/industries/logistics',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-industry-logistics.svg"
                  className="block"
                  alt="Industry Icon Logistics"
                />
              ),
            },
            {
              title: 'Manufacturing',
              href: '/industries/manufacturing',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-industry-manufacturing.svg"
                  className="block"
                  alt="Industry Icon Manufacturing"
                />
              ),
            },
            {
              title: 'Professional Services',
              href: '/industries/professional-services',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-industry-professional-services.svg"
                  className="block"
                  alt="Industry Icon Professional Services"
                />
              ),
            },
            {
              title: 'Travel',
              href: '/industries/travel',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-industry-travel.svg"
                  className="block"
                  alt="Industry Icon Travel"
                />
              ),
            },
          ],
        },
        {
          title: 'Teams',
          links: [
            {
              title: 'Customer Support',
              href: '/teams/customer-service-support',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-teams-cutomer-service.svg"
                  className="block"
                  alt="Teams Icon Customer Support"
                />
              ),
            },
            {
              title: 'Operations',
              href: '/teams/operations',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-teams-operations.svg"
                  className="block"
                  alt="Teams Icon Operations"
                />
              ),
            },
            {
              title: 'Inbound Sales',
              href: '/teams/inbound-sales',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-industry-financial.svg"
                  className="block"
                  alt="Teams Icon Inbound Sales"
                />
              ),
            },
            {
              title: 'Account Management',
              href: '/teams/account-management',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-industry-professional-services.svg"
                  className="block"
                  alt="Teams Icon Account Management"
                />
              ),
            },
            {
              title: 'Success',
              href: '/teams/customer-success',
              icon: (
                <Image
                  key="badge-1"
                  width={24}
                  height={24}
                  src="/images/icon-teams-success.svg"
                  className="block"
                  alt="Teams Icon Customer Success"
                />
              ),
            },
          ],
        },
      ],
    },
    {
      type: 'drawer' as const,
      title: 'Resources',
      sections: [
        {
          title: 'Using Front',
          links: [
            {
              title: 'Academy',
              href: 'https://academy.front.com/app',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Community',
              href: 'https://community.front.com/',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Help Center',
              href: 'https://help.front.com/',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Developer Portal',
              href: 'https://dev.frontapp.com/docs/welcome',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
          ],
        },
        {
          title: 'Explore',
          links: [
            {
              title: 'Blog',
              href: '/blog',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Integrations',
              href: '/integrations',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Guides & Webinars',
              href: '/resources',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Security',
              href: '/security',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Customer Stories',
              href: '/customer-stories',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Download App',
              href: '/download',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Front\'s Support Report',
              href: '/support-report',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
            {
              title: 'Channel Partnerships',
              href: '/partners/channel',
              /*
               *icon: (
               *  <Image
               *    key="badge-1"
               *    width={24}
               *    height={24}
               *    src="/images/icon-placeholder-mobile-menu.svg"
               *    className="block"
               *    alt="icon"
               *  />
               *),
               */
            },
          ],
        },
      ],
    },
    {
      type: 'link' as const,
      title: 'Pricing',
      href: '/pricing',
    },
    {
      type: 'link' as const,
      title: 'Sign in',
      href: 'https://app.frontapp.com/signin',
    },
  ],
  mobileMenuLinks: [
    {
      type: 'primary' as const,
      title: 'Request a demo',
      href: '',
      action: 'demo' as const,
    },
    {
      type: 'secondary' as const,
      title: 'Try for free',
      href: '',
      action: 'trial' as const,
    },
  ],
};

export default HEADER_PROPS;
