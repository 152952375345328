import { mediaBreakpointOnly, mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize } from '@/styles/typography/utils';
import styled from 'styled-components';

const Wrapper = styled.div`
  //
`;

const LogosHeading = styled.h3`
  ${customTextSize(16, 21)}
  text-align: center;
  font-weight: ${FONT_WEIGHTS.BOLD};
  margin-bottom: 40px;

  ${mediaBreakpointUp('tablet')} {
    margin-bottom: 30px;
  }

  ${mediaBreakpointUp('desktop')} {
    ${customTextSize(24, 30)}
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
    letter-spacing: -0.01em;
  }

  ${mediaBreakpointUp('desktopWide')} {
    ${customTextSize(32, 38)}
    margin-bottom: 60px;
  }
`;

const LogoList = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: var(--logo-column-gap, 40px);
  row-gap: var(--logo-row-gap, 20px);
`;

interface LogoProps {
  $isTaller: boolean;
}

const Logo = styled.div<LogoProps>`
  img {
    height: calc(${(props) => (props.$isTaller ? 52 : 39)}px * var(--logo-size-modifier, 1));
    width: auto;
  }

  ${mediaBreakpointUp('desktopWide')} {
    img {
      height: calc(${(props) => (props.$isTaller ? 58 : 43)}px * var(--logo-size-modifier, 1));
    }
  }
`;

const AwardsHeading = styled.h3`
  ${customTextSize(16, 21)}
  text-align: center;
  font-weight: ${FONT_WEIGHTS.BOLD};
  margin-top: 40px;
  margin-bottom: 26px;

  ${mediaBreakpointUp('tablet')} {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  ${mediaBreakpointUp('desktop')} {
    ${customTextSize(24, 34)}
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
    letter-spacing: -0.01em;
    margin-bottom: 40px;
  }

  ${mediaBreakpointUp('desktopWide')} {
    margin-top: 60px;
  }
`;

const AwardList = styled.div`
  ${mediaBreakpointOnly('mobile')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;

    > :nth-child(3n - 2) {
      margin-top: 32px;
    }

    > :nth-child(3n - 1) {
      margin-top: 16px;
    }
  }

  ${mediaBreakpointUp('tablet')} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 18px;
    align-items: center;
    justify-content: center;
  }

  ${mediaBreakpointUp('desktop')} {
    column-gap: 27px;
  }

  ${mediaBreakpointUp('desktopWide')} {
    column-gap: 50px;
  }
`;

interface AwardProps {
  $sizeMultiplier?: number;
}

const Award = styled.div<AwardProps>`
  > img {
    width: auto;
    height: ${(props) => 78 * (props.$sizeMultiplier ?? 1)}px;
  }

  ${mediaBreakpointOnly('mobile')} {
    margin: 0 auto;
  }

  ${mediaBreakpointUp('tablet')} {
    > img {
      height: ${(props) => 82 * (props.$sizeMultiplier ?? 1)}px;
    }
  }

  ${mediaBreakpointUp('desktop')} {
    > img {
      height: ${(props) => 122 * (props.$sizeMultiplier ?? 1)}px;
    }
  }
`;

const logoFarmComponents = {
  Wrapper,
  LogosHeading,
  LogoList,
  Logo,
  AwardsHeading,
  AwardList,
  Award,
};

export default logoFarmComponents;
