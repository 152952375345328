import Image from '@/components/Image';
import Styled from '@/components/LogoFarm.styled';
import { FC } from 'react';

interface LogoFarmProps {
  logosHeading?: string;
  logos: {
    id: string | number;
    customerName: string;
    svgUrl: string;
    height: number;
    width: number;
    isTaller: boolean;
  }[];
  awardsHeading?: string;
  awards: {
    id: string | number;
    svgUrl: string;
    width: number;
    height: number;
    sizeMultiplier?: number;
  }[];
}

const LogoFarm: FC<LogoFarmProps> = (props) => {
  return (
    <Styled.Wrapper>
      {props.logosHeading ? (
        <Styled.LogosHeading id="logo-farm-heading">{props.logosHeading}</Styled.LogosHeading>
      ) : null}

      <Styled.LogoList>
        {props.logos.map((logo) => (
          <Styled.Logo key={logo.id} $isTaller={logo.isTaller}>
            <Image src={logo.svgUrl} width={logo.width} height={logo.height} alt={`${logo.customerName} logo`} />
          </Styled.Logo>
        ))}
      </Styled.LogoList>

      {props.awards.length > 0 && (
        <>
          {props.awardsHeading ? (
            <Styled.AwardsHeading id="logo-farm-awards-heading">{props.awardsHeading}</Styled.AwardsHeading>
          ) : null}

          <Styled.AwardList>
            {props.awards.map((award) => (
              <Styled.Award key={award.id} $sizeMultiplier={award.sizeMultiplier}>
                <Image src={award.svgUrl} width={award.width} height={award.height} alt="Award badge" />
              </Styled.Award>
            ))}
          </Styled.AwardList>
        </>
      )}
    </Styled.Wrapper>
  );
};

export default LogoFarm;

LogoFarm.defaultProps = {
  logos: [],
  awards: [],
};
