import { useOptimizelyDecision } from '@/context/optimizely';

export const useClearbitDemoCTATest = () => {
  return useOptimizelyDecision({
    campaignId: '29625820587',
    variationIds: ['29601970506', '29660740020'],
    defaultVariation: '29601970506',
  });
};

export const useClearbitTrialCTATest = () => {
  return useOptimizelyDecision({
    campaignId: '29612190463',
    variationIds: ['29609980537', '29697000041'],
    defaultVariation: '29609980537',
  });
};
