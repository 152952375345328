import { ReactComponent as CaretGlyph } from '@/assets/images/svg/glyphs/caret.svg';
import { ReactComponent as FrontLogoHorizontal } from '@/assets/images/svg/logos/front/front-logo-horizontal.svg';
import { ReactComponent as FrontLogo } from '@/assets/images/svg/logos/front/front-logo-icon.svg';
import PrimaryInnerGlowButton from '@/components/buttons/primary';
import SecondaryDefaultButton from '@/components/buttons/secondary';
import Link from '@/components/Link';
import { useClearbitDemoCTATest, useClearbitTrialCTATest } from '@/constants/ab-test-data';
import { OPTIMIZELY_EVENTS } from '@/constants/optimizely';
import { APP_SIGN_IN, HOMEPAGE } from '@/constants/routes';
import { DEMO_CTA_TEXT, TRIAL_CTA_TEXT } from '@/constants/strings';
import { GlobalContext } from '@/context';
import { Maybe } from '@/graphql';
import gtm from '@/lib/gtm';
import useTimeout from '@/lib/hooks/useTimeout';
import useWindowScroll from '@/lib/hooks/useWindowScroll';
import optimizely from '@/lib/optimizely';
import { maybeNotSet, notEmpty } from '@/lib/typescript-helpers';
import { addElementIdentifier, ConditionalWrapper } from '@/lib/utils';
import { LockBodyScroll } from '@/styles/layout/body';
import { Fade as Hamburger } from 'hamburger-react';
import { FC, MouseEventHandler, useContext, useEffect, useMemo, useState } from 'react';
import Styled from './components';
import constants from './constants';

const ANALYTICS_CATEGORY = 'Primary Navigation';

interface PrimaryNavigationProps {
  forceOpaque?: boolean;
  inverted?: boolean;
}

const PrimaryNavigation: FC<PrimaryNavigationProps> = ({ forceOpaque, inverted }) => {
  const {
    navigation: { primaryNav },
    toggleTrialModal,
    toggleDemoModal,
    forceInvertedNav,
  } = useContext(GlobalContext);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [expandedSubmenu, setExpandedSubmenu] = useState('');
  const [timeoutActive, setTimeoutActive] = useState(false);
  const [scrollY] = useWindowScroll();

  const [, isDemoVariant] = useClearbitDemoCTATest();
  const [, isTrialVariant] = useClearbitTrialCTATest();

  /* Set a timeout when clicking a submenu link to force it to hide */
  useTimeout(
    () => {
      setTimeoutActive(false);
    },
    timeoutActive ? 500 : null,
  );

  const isTransparent = useMemo(() => {
    return !(forceOpaque || mobileNavOpen || scrollY > 15);
  }, [forceOpaque, scrollY, mobileNavOpen]);

  /* Close the subnav when closing the mobile nav */
  useEffect(() => {
    if (!mobileNavOpen) {
      setExpandedSubmenu('');
    }
  }, [mobileNavOpen]);

  const onLogoClick: MouseEventHandler<HTMLAnchorElement> = () => {
    gtm.track('click', {
      location: ANALYTICS_CATEGORY,
      element_type: 'link',
      element_label: 'Front Logo',
    });

    setMobileNavOpen(false);
  };

  const onMobileNavToggle = (opened: boolean) => {
    gtm.track('click', {
      location: ANALYTICS_CATEGORY,
      element_type: 'button',
      element_label: `Mobile Nav - Toggled ${opened ? 'Open' : 'Closed'}`,
    });
  };

  const onClickTryForFree = () => {
    toggleTrialModal(true);

    gtm.track('click', {
      location: ANALYTICS_CATEGORY,
      element_type: 'button',
      element_label: TRIAL_CTA_TEXT,
    });

    optimizely.event(OPTIMIZELY_EVENTS.PRIMARY_NAV_CTA_CLICK);
    optimizely.event(OPTIMIZELY_EVENTS.PRIMARY_NAV_TRIAL_CTA_CLICK);

    setMobileNavOpen(false);
  };

  const onClickRequestDemo = () => {
    toggleDemoModal(true);

    gtm.track('click', {
      location: ANALYTICS_CATEGORY,
      element_type: 'button',
      element_label: DEMO_CTA_TEXT,
    });

    optimizely.event(OPTIMIZELY_EVENTS.PRIMARY_NAV_CTA_CLICK);
    optimizely.event(OPTIMIZELY_EVENTS.PRIMARY_NAV_DEMO_CTA_CLICK);

    setMobileNavOpen(false);
  };

  const onClickSignIn = () => {
    gtm.track('click', {
      location: ANALYTICS_CATEGORY,
      element_type: 'link',
      element_label: 'Sign In',
    });
  };

  const ctas = isDemoVariant ? (
    <PrimaryInnerGlowButton type="button" onClick={onClickRequestDemo}>
      {DEMO_CTA_TEXT}
    </PrimaryInnerGlowButton>
  ) : isTrialVariant ? (
    <PrimaryInnerGlowButton type="button" onClick={onClickTryForFree}>
      {TRIAL_CTA_TEXT}
    </PrimaryInnerGlowButton>
  ) : (
    <>
      <PrimaryInnerGlowButton type="button" onClick={onClickTryForFree}>
        {TRIAL_CTA_TEXT}
      </PrimaryInnerGlowButton>

      <SecondaryDefaultButton type="button" onClick={onClickRequestDemo}>
        {DEMO_CTA_TEXT}
      </SecondaryDefaultButton>
    </>
  );

  const primaryLinks = primaryNav?.filter(notEmpty).map((link) => {
    const onClickParent = (shouldClose: boolean) => {
      const submenuId = link.id!;

      gtm.track('click', {
        location: ANALYTICS_CATEGORY,
        element_type: 'link',
        element_label: maybeNotSet(link.title),
      });

      if (shouldClose) {
        setMobileNavOpen(false);
      }

      if (expandedSubmenu !== submenuId) {
        // Open
        setExpandedSubmenu(submenuId);
      } else {
        // Close
        setExpandedSubmenu('');
      }
    };

    const onClickChild = (categoryTitle: Maybe<string>, childTitle: Maybe<string>) => {
      gtm.track('click', {
        location: ANALYTICS_CATEGORY,
        element_type: 'link',
        element_label: `${maybeNotSet(link.title)} > ${maybeNotSet(categoryTitle)} > ${maybeNotSet(childTitle)}`,
      });

      setMobileNavOpen(false);
      setTimeoutActive(true);
    };

    const linkChildren = link.children || [];
    const hasSubmenu = linkChildren.length > 0;
    const shouldWrapWithLink = !!link.url && !hasSubmenu;

    return (
      <Styled.LinkListItem key={link.id!}>
        {/* Only wrap with a link if it does not have a submenu */}
        <ConditionalWrapper
          condition={shouldWrapWithLink}
          wrapper={(children) => <Link href={link.url!}>{children}</Link>}
        >
          <Styled.LinkListAnchor onClick={() => onClickParent(shouldWrapWithLink)}>
            <span>{link.title}</span>

            <Styled.SubmenuCaret isVisible={hasSubmenu} flip={expandedSubmenu === link.id}>
              <CaretGlyph width={24} />
            </Styled.SubmenuCaret>
          </Styled.LinkListAnchor>
        </ConditionalWrapper>

        {hasSubmenu && (
          <Styled.Submenu
            isVisible={expandedSubmenu === link.id}
            pushLeft={linkChildren.length > 1}
            disablePointerEvents={timeoutActive}
          >
            {/* Loop each category/column */}
            {link.children?.filter(notEmpty).map((category) => {
              const childCount = category.children?.filter(notEmpty).length ?? 0;
              const maxPerMultiColumnRow = (link.columnSplitThreshold as Maybe<number>) ?? 0;
              const renderAsMultipleColumns = maxPerMultiColumnRow ? childCount >= maxPerMultiColumnRow : false;

              return (
                <Styled.CategoryColumn key={category.id!} columns={renderAsMultipleColumns ? 2 : 1}>
                  <Styled.CategoryEyebrow>{category.title}</Styled.CategoryEyebrow>

                  <Styled.SubmenuList rows={renderAsMultipleColumns ? Math.ceil(childCount / 2) : childCount}>
                    {/* Loop each link */}
                    {category.children?.filter(notEmpty).map((child) => (
                      <Styled.SubmenuItem key={child.id!} {...addElementIdentifier(`primary-nav-item-${child.id}`)}>
                        <Link href={child.url ?? '#'}>
                          <a title={child.title!} onClick={() => onClickChild(category.title!, child.title!)}>
                            <Styled.SubmenuItemInner>
                              <p>
                                <Styled.SubmenuItemTitle>{child.title}</Styled.SubmenuItemTitle>
                                {child.plainTextDescription}
                              </p>
                            </Styled.SubmenuItemInner>
                          </a>
                        </Link>
                      </Styled.SubmenuItem>
                    ))}
                  </Styled.SubmenuList>
                </Styled.CategoryColumn>
              );
            })}
          </Styled.Submenu>
        )}
      </Styled.LinkListItem>
    );
  });

  return (
    <>
      <Styled.Navigation
        isTransparent={isTransparent}
        fixed={mobileNavOpen}
        inverted={(forceInvertedNav || inverted) ?? false}
      >
        <Styled.NavigationContainer>
          <Link href={HOMEPAGE}>
            <Styled.LogoLink title="Front Homepage" onClick={onLogoClick}>
              <div className="full">
                <FrontLogoHorizontal title="Front logo" />
              </div>

              <div className="icon">
                <FrontLogo />
              </div>
            </Styled.LogoLink>
          </Link>

          <Styled.LinkList>{primaryLinks}</Styled.LinkList>

          <Styled.ButtonWrapper hideOnMobile>
            <Styled.StandaloneNavLink href={APP_SIGN_IN} onClick={onClickSignIn}>
              Sign In
            </Styled.StandaloneNavLink>

            {ctas}
          </Styled.ButtonWrapper>

          <Styled.HamburgerWrapper>
            <Hamburger toggled={mobileNavOpen} toggle={setMobileNavOpen} onToggle={onMobileNavToggle} size={24} />
          </Styled.HamburgerWrapper>
        </Styled.NavigationContainer>
      </Styled.Navigation>

      <Styled.MobileNavigation isVisible={mobileNavOpen}>
        <Styled.LinkList>
          {primaryLinks}

          <Styled.LinkListItem>
            <Link href={APP_SIGN_IN}>
              <Styled.LinkListAnchor onClick={onClickSignIn}>Sign In</Styled.LinkListAnchor>
            </Link>
          </Styled.LinkListItem>

          <Styled.MobileCTAListItem>{ctas}</Styled.MobileCTAListItem>
        </Styled.LinkList>
      </Styled.MobileNavigation>

      {mobileNavOpen && <LockBodyScroll />}
    </>
  );
};

PrimaryNavigation.defaultProps = {
  forceOpaque: false,
  inverted: false,
};

export default PrimaryNavigation;

const { PRIMARY_NAV_HEIGHT } = constants;

export { PRIMARY_NAV_HEIGHT };
