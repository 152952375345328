import { GET_STARTED_MODAL_HASH } from '@/constants/routes';
import { GlobalContext } from '@/context';
import attribution from '@/lib/attribution';
import gtm from '@/lib/gtm';
import hubspot from '@/lib/hubspot';
import { NextRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { createLogger } from '../debug';
import optimizely from '../optimizely';

const logger = createLogger('router');

const useRouterEvents = (router: NextRouter, { toggleGetStartedModal, showGetStartedModal }: GlobalContext) => {
  const [routeLoading, setRouteLoading] = useState<boolean>(false);
  const [lastHash, setLastHash] = useState<string>('');
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const handleHashChange = useCallback(
    (url: string, { shallow }: { shallow: boolean }) => {
      let [, hash] = url.split('#');

      if (hash) {
        hash = hash.toLowerCase();
        logger.info(`Hash ${lastHash ? 'changed' : 'set'} to '${hash}'`);
      } else if (lastHash) {
        logger.info(`Hash removed`);
      }

      setLastHash(hash);

      if (hash === GET_STARTED_MODAL_HASH && !showGetStartedModal) {
        logger.info('"Get Started Modal" hash present, opening modal...');
        toggleGetStartedModal(true);
      } else if (hash !== GET_STARTED_MODAL_HASH && showGetStartedModal) {
        logger.info('"Get Started Modal" hash removed, closing modal...');
        toggleGetStartedModal(false);
      }
    },
    [toggleGetStartedModal, showGetStartedModal, lastHash],
  );

  const handleRouteChangeStart = (url: string, { shallow }: { shallow: boolean }) => {
    setRouteLoading(!shallow);
  };

  const handleRouteChangeComplete = (url: string, { shallow }: { shallow: boolean }) => {
    if (initialLoad) {
      setInitialLoad(false);
      hubspot.setPagePath(url);
    }

    if (!initialLoad) {
      logger.info(`Navigated to ${url}`);

      /* Trigger Optimizely to "reactivate", mimicking a pageview */
      optimizely.activate();

      /* Track the new pageview in Hubspot */
      hubspot.trackPageView(url);
    }

    /* Disabled until CORS issues are resolved */
    // front.sendEvent(FRONT_EVENTS.PAGEVIEW, url);

    /* Trigger Google Tag Manager pageview */
    gtm.pageview(initialLoad, {
      new_route_url: url,
    });

    // Check for new attribution data
    attribution.parseQueryParams();

    // Check the hash
    handleHashChange(url, { shallow });

    setRouteLoading(false);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('hashChangeComplete', handleHashChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('hashChangeComplete', handleHashChange);
    };
  }, [router, handleHashChange]);

  useEffect(() => {
    if (router.asPath.split('#').length == 2) {
      handleHashChange(router.asPath, { shallow: false });
    }

    handleRouteChangeComplete(router.asPath, { shallow: false });
  }, []);

  return {
    routeLoading,
  };
};

export default useRouterEvents;
