import Footer from '@/components/_rebrand/Footer';
import Header from '@/components/_rebrand/Header';
import RebrandDemoModal from '@/components/_rebrand/Modals/DemoModal';
import RebrandTrialModal from '@/components/_rebrand/Modals/TrialModal';
import DemoModal from '@/components/modals/DemoModal';
import GetStartedModal from '@/components/modals/GetStartedModal/Modal';
import TrialModal from '@/components/modals/TrialModal';
import { PRIMARY_NAV_HEIGHT } from '@/components/navigation/PrimaryNavigation';
import FOOTER_PROPS from '@/constants/footer';
import HEADER_PROPS from '@/constants/header';
import { GlobalContext } from '@/context';
import '@/styles/_rebrand/main.css';
import { remCalc } from '@/styles/typography/utils';
import React, { FC, PropsWithChildren, useContext } from 'react';
import styled from 'styled-components';
const TemplateWrapper = styled.section`
  position: relative;
`;

const MainWrapper = styled.main`
  position: relative;
  margin-top: ${remCalc(-PRIMARY_NAV_HEIGHT)};
`;

interface MasterTemplateProps extends PropsWithChildren {
  showAnnouncement?: boolean;
  navigation?: React.ReactNode;
  footer?: React.ReactNode;
  globalIncludes?: React.ReactNode;
}

const MasterTemplate: FC<MasterTemplateProps> = ({ children, ...props }) => {
  const { showAnnouncement, navigation, footer, globalIncludes } = props;
  const { showGetStartedModal, showTrialModal, showDemoModal } = useContext(GlobalContext);

  return (
    <TemplateWrapper>
      {/* {showAnnouncement && <GlobalAnnouncementBanner />} */}

      {/* Navigation */}
      {navigation ?? <Header {...HEADER_PROPS} />}

      {/* Page content */}
      <MainWrapper>{children}</MainWrapper>

      {/* Footer */}
      {footer ?? <Footer {...FOOTER_PROPS} hideTopPattern={true} />}

      {/* Modals */}
      {showGetStartedModal && <GetStartedModal />}
      <TrialModal />
      {showDemoModal && <DemoModal />}

      {/* Rebrand Modals - need to include these separately due to different wiring in rebrand header */}
      <RebrandDemoModal />
      <RebrandTrialModal />

      {/* Global Includes */}
      {globalIncludes ?? null}
    </TemplateWrapper>
  );
};

MasterTemplate.defaultProps = {
  showAnnouncement: true,
};

export default MasterTemplate;
