import Styled from '@/components/pages/signup/components';
import SignupModal from '@/components/pages/signup/Modal';
import { OPTIMIZELY_PAGES } from '@/constants/optimizely';
import { GlobalContext } from '@/context';
import optimizely from '@/lib/optimizely';
import { Transition } from '@headlessui/react';
import React, { FC, useContext, useEffect } from 'react';

const TrialModal: FC = () => {
  const { showTrialModal, toggleTrialModal } = useContext(GlobalContext);

  useEffect(() => {
    if (showTrialModal) {
      const deactivate1 = optimizely.activatePage(OPTIMIZELY_PAGES.ANY_GET_STARTED_MODAL);
      const deactivate2 = optimizely.activatePage(OPTIMIZELY_PAGES.TRIAL_MODAL);

      return () => {
        deactivate1();
        deactivate2();
      };
    }
  }, [showTrialModal]);

  return (
    <Transition
      show={showTrialModal}
      as={Styled.TrialModalWrapper}
      enter="enter"
      enterFrom="enterFrom"
      enterTo="enterTo"
      leave="leave"
      leaveFrom="leaveFrom"
      leaveTo="leaveTo"
      appear
    >
      <Styled.FullscreenOverlay>
        <SignupModal
          onClose={() => {
            toggleTrialModal(false);
          }}
        />
      </Styled.FullscreenOverlay>
    </Transition>
  );
};

export default TrialModal;
