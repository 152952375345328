import PrimaryInnerGlowButton from '@/components/buttons/primary';
import Image from '@/components/Image';
import Link from '@/components/Link';
import { HubspotFieldNames } from '@/constants/hubspot';
import { OPTIMIZELY_PAGES } from '@/constants/optimizely';
import { WATCH_DEMO } from '@/constants/routes';
import { WATCH_DEMO_BYPASS_KEY } from '@/constants/session-storage';
import { GlobalContext } from '@/context';
import { ChiliPiperSession, ChiliPiperTargetID } from '@/lib/chili-piper';
import gtm from '@/lib/gtm';
import { setBypassCookie } from '@/lib/hooks/useBypassCookie';
import optimizely from '@/lib/optimizely';
import { COLORS } from '@/styles/color';
import { mediaBreakpointDown, mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { remCalc } from '@/styles/typography/utils';
import dayjs from 'dayjs';
import dayjsCalendarPlugin from 'dayjs/plugin/calendar';
import { FC, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

dayjs.extend(dayjsCalendarPlugin);

interface DemoRequestedProps {
  formValues: { [key: string]: string };
  isPayingCustomer: boolean;
}

const DemoRequested: FC<DemoRequestedProps> = ({ formValues, isPayingCustomer }) => {
  const { toggleGetStartedModal } = useContext(GlobalContext);
  const [showFindAnotherTimeCTA, setShowFindAnotherTimeCTA] = useState(false);
  const [showMiniConfirmation, setShowMiniConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [meetingType, setMeetingType] = useState<'scheduled' | 'phone' | null>(null);
  const [forceSchedule, setForceSchedule] = useState(false);
  const chiliPiperSession = useRef<ChiliPiperSession>();

  if (!chiliPiperSession.current) {
    chiliPiperSession.current = new ChiliPiperSession(
      {
        email: formValues[HubspotFieldNames.Email],
        first_name: formValues[HubspotFieldNames.FirstName],
        last_name: formValues[HubspotFieldNames.LastName],
        company_name: formValues[HubspotFieldNames.CompanyName],
        company_size: formValues[HubspotFieldNames.CompanySize],
        industry: formValues[HubspotFieldNames.Industry],
        phone: formValues[HubspotFieldNames.Phone],
      },
      (eventName, eventArgs) => {
        switch (eventName) {
          case 'phone-selected':
            setMeetingType('phone');
            break;
          case 'availability-loaded':
            setMeetingType('scheduled');
            setShowFindAnotherTimeCTA(true);
            break;
          case 'timeslot-selected':
            setShowFindAnotherTimeCTA(false);
            break;
          case 'booked':
          case 'booking-confirmed':
            setShowMiniConfirmation(true);
            break;
          case 'phoneClose':
            setShowConfirmation(true);
            break;
          case 'onClose':
          case 'onError':
            setShowConfirmation(true);
            break;
          case 'onTimeout':
            toggleGetStartedModal(false);
            break;
        }
      },
    );
  }

  const onDemoClick = () => {
    setBypassCookie(WATCH_DEMO_BYPASS_KEY);

    gtm.track('click', {
      element_type: 'link',
      location: 'Get Started Modal',
      element_label: 'Product Video/Tour',
    });
  };

  // These will run when this step is mounted
  useEffect(() => {
    const deactivate = optimizely.activatePage(OPTIMIZELY_PAGES.GET_STARTED_DEMO_CONFIRMATION);

    if (isPayingCustomer && chiliPiperSession.current) {
      chiliPiperSession.current.markExistingCustomer();
    }

    // This closure will run when the modal is closed
    return () => {
      // Deactivate the page in Optimizely
      deactivate();

      // Destroy the session
      if (chiliPiperSession.current) {
        chiliPiperSession.current.destroy();
      }
    };
  }, []);

  useLayoutEffect(() => {
    const domElement = `#${ChiliPiperTargetID}`;

    // Only attempt to load the Chili Piper widget if there isn't already one open
    if (
      !isPayingCustomer &&
      !showConfirmation &&
      chiliPiperSession.current &&
      !document.querySelector(`${domElement} .chilipiper-popup`)
    ) {
      chiliPiperSession.current.loadFrame(forceSchedule);
    }
  }, [isPayingCustomer, showConfirmation, forceSchedule]);

  const confirmationText =
    meetingType === 'phone' ? (
      <>
        Hopefully we were able to connect. <br />
        If not, please schedule some dedicated time with our sales team.
      </>
    ) : (
      <>
        A Front specialist will be in touch shortly. <br />
        In the meantime, explore Front with our video demo.
      </>
    );

  if (showConfirmation || isPayingCustomer) {
    return (
      <Wrapper>
        <StepHeadline>Thanks, {formValues[HubspotFieldNames.FirstName]}!</StepHeadline>

        <StepSubhead>{confirmationText}</StepSubhead>

        {meetingType === 'phone' ? (
          <PrimaryInnerGlowButton
            onClick={() => {
              if (chiliPiperSession.current) {
                chiliPiperSession.current.destroy();
                chiliPiperSession.current = undefined;
              }

              setForceSchedule(true);
              setMeetingType(null);
              setShowConfirmation(false);
              setShowMiniConfirmation(false);
              setShowFindAnotherTimeCTA(false);
            }}
          >
            Book a meeting
          </PrimaryInnerGlowButton>
        ) : (
          <>
            <StyledImage src="/webassets/pages/get-started/product-tour.svg" width={312} height={291} alt="" />

            <Link href={WATCH_DEMO}>
              <PrimaryInnerGlowButton as="a" onClick={onDemoClick}>
                View Demo
              </PrimaryInnerGlowButton>
            </Link>
          </>
        )}

        <StepFooter>
          <PreviousStepButton
            onClick={() => {
              toggleGetStartedModal(false);
            }}
          >
            Back to Front.com
          </PreviousStepButton>
        </StepFooter>
      </Wrapper>
    );
  }

  return (
    <ChiliPiperWrapper>
      <ChiliPiperTarget id={ChiliPiperTargetID}></ChiliPiperTarget>

      {showMiniConfirmation ? (
        <div>
          <p>In the meantime, explore Front with our video demo.</p>

          <Link href={WATCH_DEMO}>
            <PrimaryInnerGlowButton as="a" onClick={onDemoClick}>
              View Demo
            </PrimaryInnerGlowButton>
          </Link>
        </div>
      ) : null}

      {showFindAnotherTimeCTA ? (
        <div>
          <p>
            <strong>Don&apos;t see a time that works for you?</strong>
            <br />
            Have a Front specialist reach out to find a time that fits your schedule
          </p>

          <PrimaryInnerGlowButton
            onClick={() => {
              if (chiliPiperSession.current) {
                chiliPiperSession.current.requestFollowUp();
              }

              setShowConfirmation(true);
            }}
          >
            Request a different time
          </PrimaryInnerGlowButton>
        </div>
      ) : null}
    </ChiliPiperWrapper>
  );
};

export default DemoRequested;

const ChiliPiperWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > :last-child {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const ChiliPiperTarget = styled.div`
  position: relative;
  width: calc(100% + 80px);
  top: -40px;
  left: -40px;
  margin-bottom: -40px;
  overflow: hidden;
  min-height: 580px;
  flex-grow: 1;
  border-radius: 10px 10px 0 0;

  .chilipiper-popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &.in-custom-element {
      position: absolute;
    }

    .chilipiper-close {
      display: none;
    }

    .chilipiper-popup-window {
      max-width: none !important; // inline style override
      max-height: none !important; // inline style override
      width: 100% !important; // inline style override
      height: 100% !important; // inline style override
      border-radius: 0 !important; // inline style override
      background: transparent;

      .routing-loader {
        position: absolute;
        width: inherit;
        max-width: inherit;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -50px;

        .routing-loader-body {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            margin-bottom: 40px;
            font-weight: 500;
            font-size: 18px;
            color: #808284;
          }
        }
      }
    }

    .chilipiper-frame {
      overflow: hidden;
      border-radius: 0 !important;
      width: 100%;
      height: 100%;
      min-width: 100%;
    }

    .fabe-first-loading-label {
      margin-top: 30px;
      margin-left: 30px;
      display: block;
    }

    .chilipiper-popup-loading {
      width: 50px;
      height: 50px;
      background-image: url('https://js.chilipiper.com/images/loading.gif');
      background-size: 100%;
    }

    [data-chili-css='loader-dot'] {
      width: 1rem;
      height: 1rem;
      background: rgba(38, 52, 64, 0.32);

      &.dot1,
      &.dot2,
      &.dot3 {
        border-radius: 100%;
        display: inline-block;
        animation: bounce 1.4s infinite ease-in-out both;
      }

      &.dot1 {
        animation-delay: -0.32s;
      }

      &.dot2 {
        animation-delay: -0.16s;
      }
    }
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;
`;

const StepHeadline = styled.h3`
  ${TEXT_SIZES[24]};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  text-align: center;

  ${mediaBreakpointUp('desktop')} {
    ${TEXT_SIZES[32]};
  }
`;

const StepSubhead = styled.p`
  font-size: ${remCalc(16)};
  line-height: ${remCalc(24)};
  text-align: center;
  margin-top: ${remCalc(8)};
  margin-bottom: ${remCalc(40)};

  ${mediaBreakpointDown('tablet')} {
    br {
      display: none;
    }
  }

  ${mediaBreakpointUp('desktop')} {
    font-size: ${remCalc(24)};
    line-height: ${remCalc(28)};
    font-weight: ${FONT_WEIGHTS.MEDIUM};
  }
`;

const StyledImage = styled(Image)`
  margin-bottom: ${remCalc(28)};
`;

const StepFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-top: ${remCalc(40)};

  ${mediaBreakpointUp('desktop')} {
    margin: auto 0 0 0;
  }
`;

const PreviousStepButton = styled.button<{ pushLeft?: boolean }>`
  appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  color: ${COLORS.BLACK};
  font-weight: ${FONT_WEIGHTS.BOLD};
  font-size: ${remCalc(16)};
  border-bottom: ${remCalc(2)} solid ${COLORS.BLACK};
  cursor: pointer;

  ${mediaBreakpointUp('desktop')} {
    margin-right: ${(props) => (props.pushLeft ? 'auto' : 0)};
  }
`;
