import { applyBreakpointPadding } from '@/styles/layout/utils';
import { remCalc } from '@/styles/typography/utils';
import styled from 'styled-components';

export const MAX_CONTAINER_WIDTH = 1200;

export const Container = styled.div`
  ${applyBreakpointPadding};

  margin: 0 auto;
  max-width: ${remCalc(MAX_CONTAINER_WIDTH)};
  box-sizing: content-box;
`;
