import PrimaryButton from '@/components/buttons/primary';
import InputField from '@/components/forms/InputField';
import PhoneField from '@/components/forms/PhoneField';
import SelectField from '@/components/forms/SelectField';
import { EMAIL_ERRORS } from '@/constants/email';
import { GTM_EVENTS, GTM_VARIABLES } from '@/constants/gtm';
import {
  HubspotCompanySizeDropdownOptions,
  HubspotCompanySizeOptions,
  HubspotFieldLabels,
  HubspotFieldNames,
  HubspotFieldValidators,
  HubspotFormIDs,
  HubspotIndustryDropdownOptions,
} from '@/constants/hubspot';
import { OPTIMIZELY_EVENTS } from '@/constants/optimizely';
import { GlobalContext } from '@/context';
import attribution from '@/lib/attribution';
import { useCustomFormik, useFormikHubspotSubmit } from '@/lib/formik';
import gtm from '@/lib/gtm';
import useEmailAvailability, { EmailAvailability } from '@/lib/hooks/useEmailAvailability';
import { checkHubspotEmailDomain } from '@/lib/hubspot';
import optimizely from '@/lib/optimizely';
import { isPersonalEmail, isWorkEmail } from '@/lib/validation';
import { mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { remCalc } from '@/styles/typography/utils';
import { useRouter } from 'next/router';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// #region - Components

const StepHeadline = styled.h3`
  ${TEXT_SIZES[24]};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  text-align: center;
  margin-bottom: ${remCalc(60)};

  ${mediaBreakpointUp('desktop')} {
    ${TEXT_SIZES[32]};
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;

  grid-column-gap: ${remCalc(40)};
  grid-row-gap: ${remCalc(20)};

  max-width: ${remCalc(550)};
  margin: 0 auto;

  ${mediaBreakpointUp('desktop')} {
    grid-template-columns: repeat(2, 1fr);
    margin-top: ${remCalc(-30)};
  }
`;

// #endregion - Components

interface InitialFormValues {
  [HubspotFieldNames.CompanyName]: string;
  [HubspotFieldNames.CompanySize]: string;
  [HubspotFieldNames.Email]: string;
  [HubspotFieldNames.FirstName]: string;
  [HubspotFieldNames.Industry]: string;
  [HubspotFieldNames.JobTitle]: string;
  [HubspotFieldNames.LastName]: string;
  [HubspotFieldNames.Phone]: string;
}

interface InitialFormProps {
  onFormSubmit: (values: InitialFormValues, forceTrial: boolean, isPayingCustomer: boolean) => void;
}

const InitialForm: FC<InitialFormProps> = ({ onFormSubmit }) => {
  const router = useRouter();
  const { getStartedModalFlow } = useContext(GlobalContext);
  const [isWorkEmailRequired, setIsWorkEmailRequired] = useState(false);
  const [isRestrictedCampaign, setIsRestrictedCampaign] = useState(false);
  const [emailStatus, setEmail] = useEmailAvailability();
  const isPayingCustomerRef = useRef<boolean>(false);

  const checkingEmail = emailStatus === EmailAvailability.Checking;

  const checkRestrictedCampaign = useCallback(
    (companyName: string, jobTitle: string) => {
      if (!isRestrictedCampaign) return false;

      const blockedCompanyNames = ['academy', 'school', 'elementary', 'university', 'college', 'junior high'];

      const blockedJobTitles = [
        'teacher',
        'student',
        'professor',
        'lecturer',
        'counselor',
        'etudiant',
        'estudiante',
        'profesor',
        'professeur',
      ];

      const isCompanyBlocked = blockedCompanyNames.some((str) => companyName.toLowerCase().includes(str));
      const isJobTitleBlocked = blockedJobTitles.some((str) => jobTitle.toLowerCase().includes(str));

      return isCompanyBlocked || isJobTitleBlocked;
    },
    [isRestrictedCampaign],
  );

  const formik = useCustomFormik<InitialFormValues>({
    initialValues: {
      [HubspotFieldNames.CompanyName]: '',
      [HubspotFieldNames.CompanySize]: '',
      [HubspotFieldNames.Email]: '',
      [HubspotFieldNames.FirstName]: '',
      [HubspotFieldNames.Industry]: '',
      [HubspotFieldNames.JobTitle]: '',
      [HubspotFieldNames.LastName]: '',
      [HubspotFieldNames.Phone]: '',
    },
    onSubmit: useFormikHubspotSubmit<InitialFormValues>({
      formId: async (values) => {
        const { getStartedAction: emailForcedFlow, isPayingCustomer } = await checkHubspotEmailDomain(
          values[HubspotFieldNames.Email],
        );

        isPayingCustomerRef.current = isPayingCustomer;

        // const flowOverride = emailForcedFlow === 'default' ? getStartedModalFlow : emailForcedFlow; // Email takes priority

        const flowOverride =
          getStartedModalFlow === 'default' || getStartedModalFlow === null ? emailForcedFlow : getStartedModalFlow; // CTA takes priority

        if (
          flowOverride === 'forceTrial' ||
          (flowOverride === 'default' &&
            (values[HubspotFieldNames.CompanySize] === HubspotCompanySizeOptions.XSmall ||
              values[HubspotFieldNames.CompanySize] === HubspotCompanySizeOptions.Small ||
              isPersonalEmail(values[HubspotFieldNames.Email]) ||
              checkRestrictedCampaign(values[HubspotFieldNames.CompanyName], values[HubspotFieldNames.JobTitle])))
        ) {
          return HubspotFormIDs.GetStartedModalPartial;
        }

        return HubspotFormIDs.RequestDemo;
      },
      extraFields: {
        [HubspotFieldNames.Affiliate]: attribution.getAffiliateFromCookie(),
      },
      name: 'Get Started Form',
      content: 'Get Started Form',
      onSuccess: (values, actions, formId) => {
        gtm.event(GTM_EVENTS.GET_STARTED_FORM_SUBMIT);

        optimizely.event(OPTIMIZELY_EVENTS.GET_STARTED_SELECTED_COMPANY_SIZE);
        optimizely.event(OPTIMIZELY_EVENTS.GET_STARTED_FORM_SUBMIT);
        optimizely.event(OPTIMIZELY_EVENTS.ANY_GET_STARTED_MODAL_FORM_SUBMIT);

        const isTrial = formId === HubspotFormIDs.GetStartedModalPartial;

        if (!isTrial) {
          gtm.trackDemoRequest({
            [GTM_VARIABLES.USER_EMAIL]: values[HubspotFieldNames.Email],
            [GTM_VARIABLES.USER_FIRST_NAME]: values[HubspotFieldNames.FirstName],
            [GTM_VARIABLES.USER_LAST_NAME]: values[HubspotFieldNames.LastName],
            [GTM_VARIABLES.USER_PHONE]: values[HubspotFieldNames.Phone],
          });

          optimizely.event(OPTIMIZELY_EVENTS.GET_STARTED_CONVERSION);
          optimizely.event(OPTIMIZELY_EVENTS.REQUEST_DEMO_SUCCESS);
        }

        onFormSubmit(values, isTrial, isPayingCustomerRef.current);
      },
      onError: (errors, values, actions) => {
        gtm.event(GTM_EVENTS.GET_STARTED_FORM_SUBMIT_FAILED);
      },
    }),
    validate: (values) => {
      const errors = {};

      errors[HubspotFieldNames.Email] = HubspotFieldValidators[HubspotFieldNames.Email](
        values[HubspotFieldNames.Email],
        {
          required: true,
          allowPersonalEmail: !isWorkEmailRequired,
          allowSharedEmail: !isWorkEmailRequired,
        },
      );

      if (
        isWorkEmailRequired &&
        isRestrictedCampaign &&
        values[HubspotFieldNames.Email]?.toLowerCase().endsWith('.edu')
      ) {
        errors[HubspotFieldNames.Email] = EMAIL_ERRORS.WORK_EMAIL_REQUIRED;
      }

      switch (emailStatus) {
        case EmailAvailability.Available:
          break;
        case EmailAvailability.Unavailable:
          errors[HubspotFieldNames.Email] = errors[HubspotFieldNames.Email] ?? EMAIL_ERRORS.EXISTS;
          break;
        case EmailAvailability.Error:
          errors[HubspotFieldNames.Email] = errors[HubspotFieldNames.Email] ?? EMAIL_ERRORS.ERROR;
          break;
        default:
          // Prevent submitting without showing an error if there isn't one set already
          errors[HubspotFieldNames.Email] = errors[HubspotFieldNames.Email] ?? '';
      }

      errors[HubspotFieldNames.FirstName] = HubspotFieldValidators[HubspotFieldNames.FirstName](
        values[HubspotFieldNames.FirstName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.LastName] = HubspotFieldValidators[HubspotFieldNames.LastName](
        values[HubspotFieldNames.LastName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.CompanyName] = HubspotFieldValidators[HubspotFieldNames.CompanyName](
        values[HubspotFieldNames.CompanyName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.Industry] = HubspotFieldValidators[HubspotFieldNames.Industry](
        values[HubspotFieldNames.Industry],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.CompanySize] = HubspotFieldValidators[HubspotFieldNames.CompanySize](
        values[HubspotFieldNames.CompanySize],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.JobTitle] = HubspotFieldValidators[HubspotFieldNames.JobTitle](
        values[HubspotFieldNames.JobTitle],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.Phone] = HubspotFieldValidators[HubspotFieldNames.Phone](
        values[HubspotFieldNames.Phone],
        {
          required: true,
        },
      );

      if (Object.values(errors).every((val) => val === undefined)) return;

      return errors;
    },
    validateOnBlur: false,
  });

  useEffect(() => {
    setEmail(formik.values[HubspotFieldNames.Email]);
  }, [formik.values[HubspotFieldNames.Email]]);

  useEffect(() => {
    if (emailStatus !== EmailAvailability.Untouched) {
      formik.setFieldTouched(HubspotFieldNames.Email, true, true);
    }
  }, [emailStatus]);

  /* Check if a work email is required */
  useEffect(() => {
    if (router.isReady && router.query['utm_campaign']) {
      let campaign = router.query['utm_campaign'];

      if (Array.isArray(campaign)) {
        campaign = campaign[campaign.length - 1];
      }

      setIsWorkEmailRequired(true);
      setIsRestrictedCampaign(campaign.toLowerCase().startsWith('ls_performance-max'));
    }
  }, [router]);

  const isNotWorkEmail = !isWorkEmail(formik.values[HubspotFieldNames.Email]);

  return (
    <>
      <StepHeadline>Tell us a bit about you</StepHeadline>

      <form onSubmit={formik.handleSubmit} noValidate>
        <FormGrid>
          <InputField
            type="text"
            name={HubspotFieldNames.FirstName}
            label={HubspotFieldLabels.FirstName}
            placeholder="Jamie"
            value={formik.values[HubspotFieldNames.FirstName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.FirstName] && !!formik.errors[HubspotFieldNames.FirstName]}
            errorMessage={formik.errors[HubspotFieldNames.FirstName] as string}
            translucent
          />

          <InputField
            type="text"
            name={HubspotFieldNames.LastName}
            label={HubspotFieldLabels.LastName}
            placeholder="Smith"
            value={formik.values[HubspotFieldNames.LastName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.LastName] && !!formik.errors[HubspotFieldNames.LastName]}
            errorMessage={formik.errors[HubspotFieldNames.LastName] as string}
            translucent
          />

          <InputField
            type="email"
            name={HubspotFieldNames.Email}
            label={HubspotFieldLabels.Email}
            placeholder="you@company.com"
            value={formik.values[HubspotFieldNames.Email]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.Email] && !!formik.errors[HubspotFieldNames.Email]}
            errorMessage={formik.errors[HubspotFieldNames.Email] as string}
            warningMessage={isNotWorkEmail ? "It's best to sign up with your work email" : undefined}
            loading={checkingEmail}
            translucent
          />

          <InputField
            type="text"
            name={HubspotFieldNames.JobTitle}
            label={HubspotFieldLabels.JobTitle}
            placeholder="Director of Operations"
            value={formik.values[HubspotFieldNames.JobTitle]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.JobTitle] && !!formik.errors[HubspotFieldNames.JobTitle]}
            errorMessage={formik.errors[HubspotFieldNames.JobTitle] as string}
            translucent
          />

          <InputField
            type="text"
            name={HubspotFieldNames.CompanyName}
            label={HubspotFieldLabels.CompanyName}
            placeholder="Your Company"
            value={formik.values[HubspotFieldNames.CompanyName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.CompanyName] && !!formik.errors[HubspotFieldNames.CompanyName]}
            errorMessage={formik.errors[HubspotFieldNames.CompanyName] as string}
            translucent
          />

          <SelectField
            name={HubspotFieldNames.Industry}
            label={HubspotFieldLabels.Industry}
            options={HubspotIndustryDropdownOptions}
            value={formik.values[HubspotFieldNames.Industry]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.Industry] && !!formik.errors[HubspotFieldNames.Industry]}
            errorMessage={formik.errors[HubspotFieldNames.Industry] as string}
            translucent
          />

          <SelectField
            name={HubspotFieldNames.CompanySize}
            label={HubspotFieldLabels.CompanySize}
            options={HubspotCompanySizeDropdownOptions}
            value={formik.values[HubspotFieldNames.CompanySize]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.CompanySize] && !!formik.errors[HubspotFieldNames.CompanySize]}
            errorMessage={formik.errors[HubspotFieldNames.CompanySize] as string}
            translucent
          />

          <PhoneField
            name={HubspotFieldNames.Phone}
            label={HubspotFieldLabels.Phone}
            value={formik.values[HubspotFieldNames.Phone]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.Phone] && !!formik.errors[HubspotFieldNames.Phone]}
            errorMessage={formik.errors[HubspotFieldNames.Phone] as string}
            translucent
          />

          <div>
            <PrimaryButton type="submit" disabled={formik.isValidating || formik.isSubmitting || checkingEmail}>
              {formik.isValidating || formik.isSubmitting ? 'Submitting' : 'Submit'}
            </PrimaryButton>
          </div>
        </FormGrid>
      </form>
    </>
  );
};

export default InitialForm;
