import ChiliPiperStep from '@/components/modals/DemoModal/ChiliPiperStep';
import FormStep, { FormStepFields } from '@/components/modals/DemoModal/FormStep';
import Step from '@/components/modals/shared/Step';
import { HubspotCompanySizeOptions, HubspotFieldNames } from '@/constants/hubspot';
import { CUSTOMER_STORIES_HOME, PRODUCT_TOUR_PAGE, RESOURCES_HOME } from '@/constants/routes';
import { DEMO_REQUESTED_SESSION_KEY } from '@/constants/session-storage';
import { ChiliPiperScript } from '@/lib/chili-piper';
import gtm from '@/lib/gtm';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import Link from 'next/link';
import { NextRouter } from 'next/router';
import { FC, useEffect, useState, useTransition } from 'react';
import { useSessionstorageState } from 'rooks';
import Shared from '../shared';
import SocialProofContent from '../shared/SocialProofContent';

interface DemoModalContentProps {
  router: NextRouter | AppRouterInstance;
  onClose: () => void;
}

const DemoModalContent: FC<DemoModalContentProps> = ({ router, onClose }) => {
  const [showGenericConfirmation, setShowGenericConfirmation] = useState(false);
  const [userData, setUserData] = useState<FormStepFields | null>(null);
  const [isPayingCustomer, setIsPayingCustomer] = useState<boolean>(false);
  const [navigationTriggered, setNavigationTriggered] = useState(false);
  const [navigationPending, startNavigation] = useTransition();
  const [, setDidRequestDemo] = useSessionstorageState<boolean>(DEMO_REQUESTED_SESSION_KEY, false);

  // Workaround to close modal after navigation b/c AppRouter does not return a promise after .push()
  useEffect(() => {
    if (navigationPending) {
      setNavigationTriggered(true);
    }
    if (!navigationPending && navigationTriggered) {
      onClose();
    }
  }, [navigationPending, navigationTriggered]);

  const showChiliPiper = !!userData;

  return (
    <>
      <Shared.Modal
        name="Demo Modal"
        onClose={() => {
          onClose();
        }}
      >
        <Shared.ModalWrapper>
          <Shared.FormColumn>
            {showGenericConfirmation ? (
              <Step
                heading={`Thank you, ${userData?.firstname ?? 'friend'}!`}
                subheading="Here's what you can expect next."
              >
                <h4>We’ll be in touch</h4>

                <p>
                  A product expert will call or email you soon to set up a time to chat. We’ll ask about your workflows
                  to prepare a personalized demo that fits your team’s unique needs.
                </p>

                <p>In the meantime, there are a few ways to explore Front on your own.</p>

                <ul>
                  <li>
                    <p>
                      <Link
                        href={PRODUCT_TOUR_PAGE}
                        onClick={() => {
                          onClose();

                          gtm.track('click', {
                            element_type: 'link',
                            element_label: 'Explore our product tour',
                            location: `Form Success - Demo Modal`,
                          });
                        }}
                      >
                        Explore our product tour &rarr;
                      </Link>
                    </p>
                  </li>

                  <li>
                    <p>
                      <Link
                        href={RESOURCES_HOME}
                        onClick={() => {
                          onClose();

                          gtm.track('click', {
                            element_type: 'link',
                            element_label: 'Check out our resource hub',
                            location: `Form Success - Demo Modal`,
                          });
                        }}
                      >
                        Check out our resource hub &rarr;
                      </Link>
                    </p>
                  </li>

                  <li>
                    <p>
                      <Link
                        href={CUSTOMER_STORIES_HOME}
                        onClick={() => {
                          onClose();

                          gtm.track('click', {
                            element_type: 'link',
                            element_label: 'Meet our customers',
                            location: `Form Success - Demo Modal`,
                          });
                        }}
                      >
                        Meet our customers &rarr;
                      </Link>
                    </p>
                  </li>
                </ul>
              </Step>
            ) : showChiliPiper ? (
              <ChiliPiperStep
                formFields={userData!}
                isPayingCustomer={isPayingCustomer}
                showGenericConfirmation={() => setShowGenericConfirmation(true)}
                onDemoClick={() => {
                  gtm.track('click', {
                    element_type: 'link',
                    element_label: 'Product Tour',
                    location: `Form Success - Demo Modal`,
                  });

                  onClose();
                }}
              />
            ) : (
              <FormStep
                onSuccess={(values, _isPayingCustomer) => {
                  setDidRequestDemo(true);
                  setIsPayingCustomer(_isPayingCustomer);
                  setUserData(values);
                }}
              />
            )}
          </Shared.FormColumn>
          <SocialProofContent />
        </Shared.ModalWrapper>
      </Shared.Modal>
      {ChiliPiperScript}
    </>
  );
};

export default DemoModalContent;
