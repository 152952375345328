export const INTERNAL_HOSTNAMES = [
  'front.com',
  'www.front.com',
  'frontapp.com',
  'www.frontapp.com',
  'beta.front.com',
  'preview.front.com',
  '.frontpreview.com',
  '.vercel.app',
  'localhost',
];

export const isInternalUrl = (url: string | URL): boolean => {
  let parsedUrl: URL;

  if (typeof url === 'string') {
    parsedUrl = new InternalURL(url);
  } else {
    parsedUrl = url;
  }

  /* Ignore the port */
  parsedUrl.port = '';

  return INTERNAL_HOSTNAMES.some((hostname) => {
    if (hostname.startsWith('.')) {
      return parsedUrl.hostname.endsWith(hostname);
    }

    return parsedUrl.hostname === hostname;
  });
};

export class InternalURL extends URL {
  constructor(url: string) {
    super(url, 'https://front.com');
  }
}
