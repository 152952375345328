import { CRAFT_PREVIEW_EXIT_ENDPOINT } from '@/constants/craft';
import { useRouter } from 'next/router';
import { FC } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const Banner = styled.div({
  position: 'fixed',
  bottom: 15,
  left: 15,
  width: 'calc(100% - 30px)',
  padding: 16,
  borderRadius: 10,
  background: 'orange',
  backgroundRepeat: 'repeat-x',
  backgroundSize: 50,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 99999,
  lineHeight: '20px',
});

const Button = styled.a({
  margin: '0 0 0 16px',
  backgroundColor: 'rgba(0,0,0,0.25)',
  padding: '8px 16px',
  borderRadius: 5,
  cursor: 'pointer',
  flexShrink: 0,
});

const BodyStyles = createGlobalStyle({
  body: {
    paddingTop: 8,
  },
});

const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const PreviewModeBanner: FC = () => {
  const router = useRouter();

  if (!router.isPreview || isInIframe()) return null;

  const exitUrl = `${CRAFT_PREVIEW_EXIT_ENDPOINT}&uri=${router.asPath.split('?')[0]}`;

  return (
    <>
      <BodyStyles />
      <Banner>
        <p>
          <strong>You are in preview mode.</strong>
          <br />
          <small>
            It&rsquo;s generally pretty slow since it bypasses the cache, so unless you&rsquo;re reviewing pending
            changes, you&rsquo;ll want to exit.
          </small>
        </p>
        <Button href={exitUrl}>Exit Preview</Button>
      </Banner>
    </>
  );
};

export default PreviewModeBanner;
