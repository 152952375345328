export type LinkedInConversionEvent = {
  /**
   * URN of the conversion rule created through API.
   */
  conversion: string;
  /**
   * Epoch timestamp in milliseconds at which the conversion event happened. Note: If your source records conversion timestamp in seconds, please insert 000 at the end to transform it to milliseconds.
   */
  conversionHappenedAt: number;
  /**
   * The unique id generated by advertisers to indicate each event. This field is optional and is used for deduplication.
   */
  eventId?: string;
  /**
   * Object containing userIds and userInfo attributes of the user who performed the conversion.
   */
  user: LinkedInConversionEventUser;
};

export type LinkedInGTMPayload = {
  conversionId: string;
  eventId: string;
  userInfo: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
};

export type LinkedInConversionEventUser = {
  userIds: {
    idType: LinkedInConversionEventUserIDType;
    idValue: string;
  }[];
  userInfo?: {
    firstName: string;
    lastName: string;
    companyName?: string;
    title?: string;
    countryCode?: string;
  };
  lead?: LinkedInLeadGenFormResponseUrn;
};

export type LinkedInLeadGenFormResponseUrn = string;

export enum LinkedInConversionEventUserIDType {
  Email = 'SHA256_EMAIL',
  ClickID = 'LINKEDIN_FIRST_PARTY_ADS_TRACKING_UUID',
}
