import { FC, useEffect, useRef } from 'react';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import { useRouter } from 'next/router';
import { createLogger } from '@/lib/debug';
import optimizely from '@/lib/optimizely';
import { OPTIMIZELY_EVENTS } from '@/constants/optimizely';

const logger = createLogger('scroll-tracker');

const ScrollTracker: FC = () => {
  const router = useRouter();
  const { scrollYProgress } = useScroll();
  const fiftyPercentTriggered = useRef(false);
  const seventyFivePercentTriggered = useRef(false);

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    if (latest >= 0.5 && !fiftyPercentTriggered.current) {
      fiftyPercentTriggered.current = true;
      optimizely.event(OPTIMIZELY_EVENTS.SCROLL_DEPTH_50PCT);
      logger.info('50% scroll depth triggered');
    }

    if (latest >= 0.75 && !seventyFivePercentTriggered.current) {
      seventyFivePercentTriggered.current = true;
      optimizely.event(OPTIMIZELY_EVENTS.SCROLL_DEPTH_75PCT);
      logger.info('75% scroll depth triggered');
    }
  });

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      logger.info('Scroll depth reset');
      fiftyPercentTriggered.current = false;
      seventyFivePercentTriggered.current = false;
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  return null;
};

export default ScrollTracker;
