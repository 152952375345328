import { GlobalContext } from '@/context';
import { EdgeConfigValue } from '@/types/vercel';
import { useContext } from 'react';

const useFeatureFlag = <T extends EdgeConfigValue>(key: string, defaultValue: T | null = null): T | null => {
  const { featureFlags } = useContext(GlobalContext);

  if (key in featureFlags) {
    /*
     * The Edge Config could contain any value, so we just have to assume it's correct.
     * Typescript throws a fit because it might not be the same type as described by
     * the generic, but we cannot absolutely guarantee that, so just ignore the error.
     */

    // @ts-expect-error
    return featureFlags[key];
  }

  return defaultValue;
};

export default useFeatureFlag;
