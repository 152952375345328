import PrimaryInnerGlowButton from '@/components/buttons/primary';
import SecondaryDefaultButton from '@/components/buttons/secondary';
import Image from '@/components/Image';
import { Container } from '@/components/layout/Container';
import { PRIMARY_NAV_HEIGHT } from '@/components/navigation/PrimaryNavigation';
import { CONTACT_US, HOMEPAGE } from '@/constants/routes';
import gtm from '@/lib/gtm';
import { mediaBreakpointUp, paddingY } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import MasterTemplate from '@/templates/MasterTemplate';
import styled from 'styled-components';

// #region - Components

const PageWrapper = styled.div`
  margin-top: ${remCalc(PRIMARY_NAV_HEIGHT)};
  ${paddingY(16)}

  ${mediaBreakpointUp('tablet')} {
    ${paddingY(40)}
  }

  ${mediaBreakpointUp('desktop')} {
    ${paddingY(100)}
  }

  h1 {
    ${customTextSize(40, 42)}
    margin-bottom: ${remCalc(16)};

    ${mediaBreakpointUp('tablet')} {
      font-weight: ${FONT_WEIGHTS.SEMIBOLD};
      text-align: center;
    }

    ${mediaBreakpointUp('desktop')} {
      text-align: left;
    }
  }

  p {
    ${customTextSize(18, 29)}

    ${mediaBreakpointUp('tablet')} {
      text-align: center;
    }

    ${mediaBreakpointUp('desktop')} {
      text-align: left;
    }
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  grid-gap: ${remCalc(40)};

  ${mediaBreakpointUp('desktop')} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${remCalc(16)};
  justify-content: flex-start;

  margin-top: ${remCalc(32)};

  ${mediaBreakpointUp('tablet')} {
    justify-content: center;
  }

  ${mediaBreakpointUp('desktop')} {
    justify-content: flex-start;
  }
`;

const ContentContainer = styled.div`
  ${mediaBreakpointUp('desktop')} {
    flex: 1 0 ${remCalc(400)};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  ${mediaBreakpointUp('desktop')} {
    flex: 1 1 ${remCalc(500)};
    margin-left: ${remCalc(40)};
  }
`;

// #endregion - Components

const ErrorPage500: PageTemplate = (props) => {
  return (
    <PageWrapper>
      <StyledContainer>
        <ContentContainer>
          <h1>Uh oh, something went wrong.</h1>
          <p>We&rsquo;re experiencing unexpected technical difficulties. Please try again.</p>

          <ButtonContainer>
            <PrimaryInnerGlowButton
              as="a"
              href={HOMEPAGE}
              onClick={() => {
                gtm.track('click', {
                  element_type: 'link',
                  element_label: 'Back to homepage',
                  location: 'Error Page',
                });
              }}
            >
              Back to homepage
            </PrimaryInnerGlowButton>

            <SecondaryDefaultButton
              as="a"
              href={CONTACT_US}
              onClick={() => {
                gtm.track('click', {
                  element_type: 'link',
                  element_label: 'Contact us',
                  location: 'Error Page',
                });
              }}
            >
              Contact us
            </SecondaryDefaultButton>
          </ButtonContainer>
        </ContentContainer>

        <ImageContainer>
          <Image src="/webassets/illustrations/404.png" width={400} height={400 * (792 / 726)} alt="" />
        </ImageContainer>
      </StyledContainer>
    </PageWrapper>
  );
};

ErrorPage500.template = MasterTemplate;

export default ErrorPage500;
