import { css } from 'styled-components';
import { FONT_WEIGHTS } from '.';

/* TODO add missing imports */

const fontimports = css`
  @font-face {
    font-family: 'Roobert';
    src: local('Roobert Regular'), local('Roobert-Regular'), url('/webassets/fonts/roobert.woff2') format('woff2'),
      url('/webassets/fonts/roobert.woff') format('woff');
    font-weight: ${FONT_WEIGHTS.NORMAL};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roobert';
    src: local('Roobert Light'), local('Roobert-Light'), url('/webassets/fonts/roobert-light.woff2') format('woff2'),
      url('/webassets/fonts/roobert-light.woff') format('woff');
    font-weight: ${FONT_WEIGHTS.LIGHT};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roobert';
    src: local('Roobert Medium'), local('Roobert-Medium'), url('/webassets/fonts/roobert-medium.woff2') format('woff2'),
      url('/webassets/fonts/roobert-medium.woff') format('woff');
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roobert';
    src: local('Roobert SemiBold'), local('Roobert-SemiBold'),
      url('/webassets/fonts/roobert-semibold.woff2') format('woff2'),
      url('/webassets/fonts/roobert-semibold.woff') format('woff');
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roobert';
    src: local('Roobert Bold'), local('Roobert-Bold'), url('/webassets/fonts/roobert-bold.woff2') format('woff2'),
      url('/webassets/fonts/roobert-bold.woff') format('woff');
    font-weight: ${FONT_WEIGHTS.BOLD};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Quincy CF';
    src: url('/webassets/fonts/quincy-cf.eot');
    src: url('/webassets/fonts/quincy-cf.eot?#iefix') format('embedded-opentype'),
      url('/webassets/fonts/quincy-cf.woff2') format('woff2'), url('/webassets/fonts/quincy-cf.woff') format('woff'),
      url('/webassets/fonts/quincy-cf.ttf') format('truetype'),
      url('/webassets/fonts/quincy-cf.svg#youworkforthem') format('svg');
    font-weight: ${FONT_WEIGHTS.NORMAL};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Quincy CF';
    src: url('/webassets/fonts/quincy-cf-italic.eot');
    src: url('/webassets/fonts/quincy-cf-italic.eot?#iefix') format('embedded-opentype'),
      url('/webassets/fonts/quincy-cf-italic.woff2') format('woff2'),
      url('/webassets/fonts/quincy-cf-italic.woff') format('woff'),
      url('/webassets/fonts/quincy-cf-italic.ttf') format('truetype'),
      url('/webassets/fonts/quincy-cf-italic.svg#youworkforthem') format('svg');
    font-weight: ${FONT_WEIGHTS.NORMAL};
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Quincy CF';
    src: url('/webassets/fonts/quincy-cf-medium.eot');
    src: url('/webassets/fonts/quincy-cf-medium.eot?#iefix') format('embedded-opentype'),
      url('/webassets/fonts/quincy-cf-medium.woff2') format('woff2'),
      url('/webassets/fonts/quincy-cf-medium.woff') format('woff'),
      url('/webassets/fonts/quincy-cf-medium.ttf') format('truetype'),
      url('/webassets/fonts/quincy-cf-medium.svg#youworkforthem') format('svg');
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Quincy CF';
    src: url('/webassets/fonts/quincy-cf-medium-italic.eot');
    src: url('/webassets/fonts/quincy-cf-medium-italic.eot?#iefix') format('embedded-opentype'),
      url('/webassets/fonts/quincy-cf-medium-italic.woff2') format('woff2'),
      url('/webassets/fonts/quincy-cf-medium-italic.woff') format('woff'),
      url('/webassets/fonts/quincy-cf-medium-italic.ttf') format('truetype'),
      url('/webassets/fonts/quincy-cf-medium-italic.svg#youworkforthem') format('svg');
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Quincy CF';
    src: url('/webassets/fonts/quincy-cf-bold.eot');
    src: url('/webassets/fonts/quincy-cf-bold.eot?#iefix') format('embedded-opentype'),
      url('/webassets/fonts/quincy-cf-bold.woff2') format('woff2'),
      url('/webassets/fonts/quincy-cf-bold.woff') format('woff'),
      url('/webassets/fonts/quincy-cf-bold.ttf') format('truetype'),
      url('/webassets/fonts/quincy-cf-bold.svg#youworkforthem') format('svg');
    font-weight: ${FONT_WEIGHTS.BOLD};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Quincy CF';
    src: url('/webassets/fonts/quincy-cf-bold-italic.eot');
    src: url('/webassets/fonts/quincy-cf-bold-italic.eot?#iefix') format('embedded-opentype'),
      url('/webassets/fonts/quincy-cf-bold-italic.woff2') format('woff2'),
      url('/webassets/fonts/quincy-cf-bold-italic.woff') format('woff'),
      url('/webassets/fonts/quincy-cf-bold-italic.ttf') format('truetype'),
      url('/webassets/fonts/quincy-cf-bold-italic.svg#youworkforthem') format('svg');
    font-weight: ${FONT_WEIGHTS.BOLD};
    font-style: italic;
    font-display: swap;
  }
`;

export default fontimports;
