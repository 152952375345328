import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { NextResponse } from 'next/server';

const useBypassCookie = (cookieName: string, deleteImmediately: boolean = true) => {
  const [cookieExisted, setCookieExisted] = useState(false);

  useEffect(() => {
    if (cookieExisted) return;

    if (Cookies.get(cookieName) !== undefined) {
      setCookieExisted(true);

      if (deleteImmediately) {
        Cookies.remove(cookieName);
      }
    }
  });

  return cookieExisted;
};

export default useBypassCookie;

export const setBypassCookie = (cookieName: string, response?: NextResponse) => {
  const params = [
    cookieName,
    'true',
    {
      // prettier-ignore
      expires: new Date().getTime() + (60 * 1000),
    },
  ] as const;

  if (response) {
    response.cookies.set(...params);
  } else {
    Cookies.set(...params);
  }
};
