import { createLogger } from '@/lib/debug';
import { isGhostInspector } from '@/lib/ghost-inspector';
import logging from '@/lib/logging';
import { LinkedInConversionEvent, LinkedInConversionEventUserIDType, LinkedInGTMPayload } from '@/types/linkedin.d';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { FC, useCallback, useEffect } from 'react';

const logger = createLogger('linkedin capi');

export const LinkedInCAPIListener: FC = () => {
  const { query } = useRouter();

  const handler = useCallback<(payload: LinkedInGTMPayload) => void>(
    (gtmPayload) => {
      try {
        if (gtmPayload.userInfo.email && isGhostInspector(gtmPayload.userInfo.email)) return;

        const payload: Partial<LinkedInConversionEvent> = {
          conversion: `urn:lla:llaPartnerConversion:${gtmPayload.conversionId}`,
          eventId: gtmPayload.eventId,
          conversionHappenedAt: Date.now(),
        };

        if (gtmPayload.userInfo && Object.keys(gtmPayload.userInfo).length) {
          payload.user = {
            userIds: [],
          };

          if (gtmPayload.userInfo.email) {
            payload.user.userIds.push({
              idType: LinkedInConversionEventUserIDType.Email,
              idValue: gtmPayload.userInfo.email,
            });
          }

          if (gtmPayload.userInfo.firstName) {
            payload.user.userInfo = {
              firstName: gtmPayload.userInfo.firstName ?? '',
              lastName: gtmPayload.userInfo.lastName ?? '',
            };
          }
        }

        const clickId =
          (Array.isArray(query.li_fat_id) ? query.li_fat_id[0] : query.li_fat_id) ?? Cookies.get('li_fat_id');

        if (clickId) {
          payload.user = payload.user || {
            userIds: [],
          };

          payload.user.userIds.push({
            idType: LinkedInConversionEventUserIDType.ClickID,
            idValue: clickId,
          });
        }

        logger.log('Triggering conversion', payload);

        fetch('/api/licapi', {
          method: 'POST',
          body: JSON.stringify(payload),
        });
      } catch (e) {
        logging.captureException(e);
      }
    },
    [query],
  );

  useEffect(() => {
    window.licapi = handler;

    return () => {
      try {
        delete window.licapi;
      } catch (e) {
        // Maybe not set
      }
    };
  }, [handler]);

  return null;
};
