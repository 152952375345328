import { OPTIMIZELY_PAGES } from '@/constants/optimizely';
import { GlobalContext } from '@/context';
import optimizely from '@/lib/optimizely';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect } from 'react';
import DemoModalContent from './DemoModalContent';

const DemoModal: FC = () => {
  const { showDemoModal, toggleDemoModal } = useContext(GlobalContext);
  const router = useRouter();

  useEffect(() => {
    if (showDemoModal) {
      const deactivate1 = optimizely.activatePage(OPTIMIZELY_PAGES.ANY_GET_STARTED_MODAL);
      const deactivate2 = optimizely.activatePage(OPTIMIZELY_PAGES.DEMO_MODAL);

      return () => {
        deactivate1();
        deactivate2();
      };
    }
  }, [showDemoModal]);

  return (
    <DemoModalContent router={router} onClose={() => toggleDemoModal(false)} />
  );
};

export default DemoModal;
