import { Maybe } from '@/graphql';

export const CRAFT_PREVIEW_ENDPOINT = '/api/preview?uri=';
export const CRAFT_PREVIEW_EXIT_ENDPOINT = '/api/preview?exit=true';
export const CRAFT_PREVIEW_SECURITY_TOKEN_PARAM_NAME = 'security-token';
export const CRAFT_PREVIEW_PARAM_NAME = 'x-craft-preview';
export const CRAFT_LIVE_PREVIEW_PARAM_NAME = 'x-craft-live-preview';
export const CRAFT_PREVIEW_TOKEN_HEADER_NAME = 'x-craft-token';
export const CRAFT_PREVIEW_TOKEN_PARAM_NAME = 'craftToken';

export type CraftValue<T> = Maybe<T> | undefined;

export type CraftWebinarType = CraftValue<'webinar' | 'event' | 'regionalEvent'>;
