import PreviewModeBanner from '@/components/banners/PreviewModeBanner';
import GDPRConsentModal from '@/components/modals/GDPRConsentModal';
import { GlobalContext, useGlobalContext } from '@/context';
import { OneTrustCategories, OneTrustConsentWrapper, OneTrustContext, useOneTrustContext } from '@/context/onetrust';
import { OptimizelyContext, useOptimizelyContext } from '@/context/optimizely';
import { UserContext, useUserContext } from '@/context/user';
import { FrontChatScript } from '@/lib/chat';
import { ClearbitScript } from '@/lib/clearbit';
import front from '@/lib/front';
import { GoogleTagManagerNoScript, GoogleTagManagerScript } from '@/lib/gtm';
import useRouterEvents from '@/lib/hooks/useRouterEvents';
import { HubspotTrackingScript } from '@/lib/hubspot';
import { LinkedInCAPIListener } from '@/lib/linkedin';
import { CraftMetadata } from '@/lib/metadata';
import { UniversalPageProps } from '@/lib/next';
import ScrollTracker from '@/lib/scroll-tracker';
import { LoadingState } from '@/styles/GlobalStyles';
import NextAdapterPages from 'next-query-params/pages';
import { AppProps } from 'next/app';
import { FC, PropsWithChildren, useEffect } from 'react';
import { QueryParamProvider } from 'use-query-params';

const App = ({ Component, pageProps, router }: AppProps<UniversalPageProps>) => {
  const { global, metadata, ...props } = pageProps;
  const globalContext = useGlobalContext(global);
  const routerEvents = useRouterEvents(router, globalContext);
  const oneTrustContext = useOneTrustContext();
  const optimizelyContext = useOptimizelyContext();
  const userContext = useUserContext();

  useEffect(() => {
    window.__front = window.__front || {};
    window._hsq = window._hsq || []; // Hubspot

    front.setFaid();
  }, []);

  /* Load iFrame resizer for Craft live preview */
  useEffect(() => {
    // eslint-disable-next-line import/extensions
    if (router.isPreview) import('iframe-resizer/js/iframeResizer.contentWindow.js');
  }, []);

  const Layout = ((): FC<PropsWithChildren<any>> => {
    const { template } = Component as PageTemplate;

    return template || (({ children }) => children);
  })();

  return (
    <>
      {routerEvents.routeLoading && <LoadingState />}
      {metadata && <CraftMetadata metadata={metadata} />}

      <QueryParamProvider adapter={NextAdapterPages}>
        <OneTrustContext.Provider value={oneTrustContext}>
          {/* Google Tag Manager */}
          <OneTrustConsentWrapper>{GoogleTagManagerNoScript}</OneTrustConsentWrapper>

          <OptimizelyContext.Provider value={optimizelyContext}>
            <GlobalContext.Provider value={globalContext}>
              <UserContext.Provider value={userContext}>
                <Layout {...props}>
                  <Component {...props} />
                </Layout>
                <PreviewModeBanner />
                <FrontChatScript />
                <LinkedInCAPIListener />

                <OneTrustConsentWrapper>
                  <GDPRConsentModal />
                </OneTrustConsentWrapper>
              </UserContext.Provider>
            </GlobalContext.Provider>
          </OptimizelyContext.Provider>

          {/* Google Tag Manager */}
          <OneTrustConsentWrapper>{GoogleTagManagerScript}</OneTrustConsentWrapper>

          <OneTrustConsentWrapper groupId={OneTrustCategories.Performance}>
            {/* Hubspot Tracking */}
            {HubspotTrackingScript}
            {/* Clearbit */}
            {ClearbitScript}
          </OneTrustConsentWrapper>
        </OneTrustContext.Provider>
      </QueryParamProvider>

      <ScrollTracker />
    </>
  );
};

export default App;
