import { GTM_EVENTS, GTM_VARIABLES } from '@/constants/gtm';
import { createLogger } from '@/lib/debug';
import gtm from '@/lib/gtm';
import optimizely from '@/lib/optimizely';
import { maybeNotSet } from '@/lib/typescript-helpers';
import { useRouter } from 'next/router';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const logger = createLogger('optimizely');

export const OptimizelyContext = createContext<OptimizelyContext>({
  campaignIds: [],
  variationIds: [],
});

export type OptimizelyContext = {
  campaignIds: string[];
  variationIds: string[];
};

const optimizelyReadyHandler = () => {
  logger.log('Removing white-out...');
  document.documentElement.classList.remove('hide');
};

export const useOptimizelyContext = (): OptimizelyContext => {
  const router = useRouter();
  const [campaignIds, setCampaignIds] = useState<string[]>([]);
  const [variationIds, setVariationIds] = useState<string[]>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!initialLoad) return;

    if (window.optimizelyWaitForChanges === true) {
      logger.log('optimizelyWaitForChanges is set to true, setting white-out timeout');
      timeoutRef.current = setTimeout(optimizelyReadyHandler, 5000);
    } else if (document.documentElement.classList.contains('hide')) {
      logger.log('optimizelyWaitForChanges was not set, removing white-out');
      optimizelyReadyHandler();
    }
  }, [initialLoad]);

  useEffect(() => {
    const routeChangeCompleteHandler = () => {
      setInitialLoad(false);
    };

    router.events.on('routeChangeComplete', routeChangeCompleteHandler);

    if (!window.optimizelyActionAppliedListenerAdded) {
      window.optimizelyActionAppliedListenerAdded = true;

      const handler = () => {
        optimizelyReadyHandler();

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };

      if (window.optimizelyActionAppliedCount) {
        handler();
      } else {
        window.optimizely.push({
          type: 'addListener',
          filter: {
            type: 'action',
            name: 'applied',
          },
          handler,
        });
      }
    }

    return () => {
      router.events.off('routeChangeComplete', routeChangeCompleteHandler);
    };
  }, []);

  const addCampaignDecision = useCallback(
    (campaignId: string, variationId: string) => {
      setCampaignIds(Array.from(new Set([...campaignIds, campaignId])));
      setVariationIds(Array.from(new Set([...variationIds, variationId])));
    },
    [campaignIds, variationIds],
  );

  useEffect(() => {
    window.optimizelyCampaignDecidedListener = addCampaignDecision;
  }, [addCampaignDecision]);

  useEffect(() => {
    const { campaignIds, variationIds } = optimizely.getState();

    setCampaignIds(campaignIds);
    setVariationIds(variationIds);

    if (variationIds.length) {
      gtm.event(GTM_EVENTS.OPTIMIZELY_DECISION, {
        [GTM_VARIABLES.OPTIMIZELY_VARIATION_IDS]: variationIds.join(','),
      });
    }

    if (!window.optimizelyCampaignDecidedListenerAdded) {
      window.optimizelyCampaignDecidedListenerAdded = true;

      window.optimizely.push({
        type: 'addListener',
        filter: {
          type: 'lifecycle',
          name: 'campaignDecided',
        },
        handler: (event) => {
          const campaignName = maybeNotSet(event.data.campaign.name ?? event.data.campaign.id);
          const { variationId } = event.data.decision;

          if (!variationId) {
            logger.info(`Not activating campaign '${campaignName}': no variation is set`);

            return;
          }

          logger.info(`Variation '${variationId}' of campaign '${campaignName}' activated`);

          window.optimizelyCampaignDecidedListener(event.data.campaign.id, variationId);

          const { variationIds } = optimizely.getState();

          gtm.event(GTM_EVENTS.OPTIMIZELY_DECISION, {
            [GTM_VARIABLES.OPTIMIZELY_VARIATION_IDS]: variationIds.join(','),
          });
        },
      });
    }
  }, []);

  return {
    campaignIds,
    variationIds,
  };
};

interface OptimizelyDecisionProps extends PropsWithChildren {
  campaignId: string;
  variationId: string;
  showByDefault?: boolean;
}

export const OptimizelyDecision: FC<OptimizelyDecisionProps> = ({
  campaignId,
  variationId,
  showByDefault,
  children,
}) => {
  const { campaignIds, variationIds } = useContext(OptimizelyContext);

  if (
    (campaignIds.includes(campaignId) && variationIds.includes(variationId)) ||
    (!campaignIds.includes(campaignId) && showByDefault)
  ) {
    return children;
  }

  return null;
};

OptimizelyDecision.defaultProps = {
  showByDefault: false,
};

export const useOptimizelyDecision = ({
  defaultVariation,
  campaignId,
  variationIds,
}: {
  defaultVariation?: string;
  campaignId: string;
  variationIds: string[];
}) => {
  const { campaignIds: activeCampaignIds, variationIds: activeVariationIds } = useContext(OptimizelyContext);

  const decisions = useMemo(() => {
    return variationIds.map((variationId, index) => {
      return (
        (activeCampaignIds.includes(campaignId) && activeVariationIds.includes(variationId)) ||
        (!activeCampaignIds.includes(campaignId) && defaultVariation === variationId)
      );
    });
  }, [activeCampaignIds, activeVariationIds, defaultVariation]);

  return decisions;
};
