import { PRIMARY_NAV_HEIGHT } from '@/components/navigation/PrimaryNavigation';
import { EXTERNAL_ASSET_PATHS, GET_STARTED_MODAL_HASH, TEMPORARILY_UNAVAILABLE_PATHS } from '@/constants/routes';
import { GlobalContext } from '@/context';
import { parseUrlFromCraft } from '@/lib/craft-utils';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, {
  FC,
  isValidElement,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutationObserverRef } from 'rooks';
import { isStyledComponent } from 'styled-components';

interface LinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps>,
    NextLinkProps,
    React.RefAttributes<HTMLAnchorElement>,
    PropsWithChildren {}

/**
 * Why does this exist?
 * -----------------------
 * There is is history with the Next.js Link component. In Next 12, the child element
 * had to be a <a> tag. In Next 13, this is not allowed. (Well, it is, with some additional
 * configuration, which is what this component does)
 * See: https://nextjs.org/docs/upgrading#link-component
 *
 * So this component helps bridge the gap by automatically detecting if the legacyBehavior or
 * passHref flags need to be set. In addition, this component exists to prevent certain pages
 * and assets from being Next.js Links and instead use normal <a> tags to prevent client-side
 * routing.
 */

const Link: FC<LinkProps> = (props) => {
  const { toggleTrialModal, toggleDemoModal } = useContext(GlobalContext);
  const router = useRouter();
  const [href, setHref] = useState(props.href ?? '#');

  const [url, isAsset, isNonNextPage, isTryForFree, isRequestDemo] = useMemo(() => {
    const parsedUrl = typeof href !== 'object' ? parseUrlFromCraft(href) : href;

    const pathToMatch = parsedUrl.toString().replace(/^\//, '');
    const isAsset = EXTERNAL_ASSET_PATHS.test(pathToMatch);
    const isNonNextPage = TEMPORARILY_UNAVAILABLE_PATHS.some((pattern) => pattern.test(pathToMatch));
    const isTryForFree = parsedUrl.toString() === '/signup';
    const isRequestDemo = parsedUrl.toString() === '/request-demo';

    return [parsedUrl, isAsset, isNonNextPage, isTryForFree, isRequestDemo];
  }, [href]);

  /**
   * This allows us to update the href on a link for A/B testing and have the router honor the new URL
   */
  const onMutation = useCallback(
    (mutations: MutationRecord[]) => {
      mutations.forEach((mutation) => {
        const link = mutation.target as HTMLAnchorElement;
        const oldUrl = new URL(mutation.oldValue ?? href.toString(), window.location.origin);
        const newUrl = new URL(link.href, window.location.origin);

        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'href' &&
          newUrl.toString() !== oldUrl.toString()
        ) {
          setHref(link.href);
        }
      });
    },
    [href],
  );

  const [mutationRef] = useMutationObserverRef(onMutation, {
    attributeFilter: ['href'],
    attributeOldValue: true,
    attributes: true,
    characterData: false,
    characterDataOldValue: false,
    childList: false,
    subtree: false,
  });

  /**
   * If the props are updated, update the state to match
   */
  useEffect(() => {
    setHref(props.href ?? '#');
  }, [props.href]);

  const ref = useCallback(
    (node: HTMLElement | null) => {
      mutationRef(node);

      if (node === null || !href.toString().startsWith('#')) return;

      const onClick = (e: Event) => {
        const hash = href.toString().slice(1);
        const target = document.getElementById(hash);

        if (hash === GET_STARTED_MODAL_HASH || !target) return;

        e.preventDefault();

        window.scrollTo({
          top: target.offsetTop - PRIMARY_NAV_HEIGHT - 20,
          behavior: 'smooth',
        });
      };

      node.addEventListener('click', onClick);

      return () => node.removeEventListener('click', onClick);
    },
    [mutationRef, href],
  );

  const target = isAsset ? '_blank' : '_self';
  let passHref = props.passHref ?? false;
  let legacyBehavior = props.legacyBehavior ?? false;

  if (isValidElement(props.children)) {
    if (props.children.type === 'a') {
      // https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-tag
      legacyBehavior = true;
    }

    // Check if this is a styled component
    if (isStyledComponent(props.children.type)) {
      // https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-custom-component-that-wraps-an-a-tag
      passHref = true;

      if (props.children.props.as === 'a' || ('target' in props.children.type && props.children.type.target === 'a')) {
        // https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-tag
        legacyBehavior = true;
      }
    }

    if (isTryForFree || isRequestDemo) {
      return (
        <props.children.type
          {...props.children.props}
          ref={ref}
          href={url.toString()}
          target={target}
          onClick={(e) => {
            e.preventDefault();

            if (props.onClick) props.onClick(e);

            if (isTryForFree) {
              toggleTrialModal(true);
            } else if (isRequestDemo) {
              toggleDemoModal(true);
            }
          }}
        />
      );
    }

    if (isAsset || isNonNextPage) {
      return (
        <props.children.type
          {...props.children.props}
          ref={ref}
          href={url.toString()}
          target={target}
          data-regular-link="true"
        />
      );
    }
  }

  // Not a React element/component (could be a string)

  if (isAsset || isNonNextPage) {
    return <a {...props} ref={ref} href={url.toString()} target={target} data-regular-link="true" />;
  }

  return (
    <NextLink
      {...props}
      ref={ref}
      href={url}
      legacyBehavior={legacyBehavior}
      passHref={passHref}
      prefetch={props.prefetch ?? false}
    />
  );
};

export default Link;
