import { PRIMARY_NAV_HEIGHT } from '@/components/navigation/PrimaryNavigation';
import { COLORS } from '@/styles/color';
import { BREAKPOINT_PADDING, BREAKPOINTS } from '@/styles/layout';
import { FONT_FAMILIES } from '@/styles/typography';
import { BASE_FONT_SIZE } from '@/styles/typography/base';
import { remCalc } from '@/styles/typography/utils';
import { createGlobalStyle, css } from 'styled-components';
import fontimports from './typography/font-imports';

export default createGlobalStyle`
  :root {
    --breakpoint-all-active: ${Object.keys(BREAKPOINTS)[0]};
    --breakpoint-current: ${Object.keys(BREAKPOINTS)[0]};
    --breakpoint-padding-x: ${Object.values(BREAKPOINT_PADDING)[0]}px;
    --breakpoint-x: ${Object.values(BREAKPOINTS)[0]}px;

    /* Variables */
    ${Object.keys(BREAKPOINTS).map((key, index, arr) => {
      if (index === 0) return;

      const width = BREAKPOINTS[key];
      const padding = BREAKPOINT_PADDING[key];

      return css`
        @media (min-width: ${width}px) {
          --breakpoint-all-active: ${arr.slice(0, index + 1).join(', ')};
          --breakpoint-current: ${key};
          --breakpoint-padding-x: ${padding}px;
          --breakpoint-x: ${width}px;
        }
      `;
    })}
    
    scroll-padding-top: ${remCalc(PRIMARY_NAV_HEIGHT + BASE_FONT_SIZE)};

    /* Base */
    font-size: ${BASE_FONT_SIZE}px;
    font-family: ${FONT_FAMILIES.SANS_SERIF};
    font-feature-settings: 'tilt', 'salt', 'ss04';
    color: ${COLORS.BLACK};

    /* Optimizely Hide */
    will-change: opacity;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    transition-delay: 0.1s;

    &.hide {
      opacity: 0;
      transition-delay: 0s;
      transition-duration: 0s;
    }
  }

  ${fontimports}
  
  body {
    will-change: background-color;
    transition: background-color 400ms linear;
    background-color: var(--body-background-color, ${COLORS.WHITE});
  }
`;

export const LoadingState = createGlobalStyle`
  :root * {
    cursor: progress !important;
  }
`;
