import PrimaryButton, { PrimaryDefaultButton } from '@/components/buttons/primary';
import SecondaryDefaultButton from '@/components/buttons/secondary';
import { Container, MAX_CONTAINER_WIDTH } from '@/components/layout/Container';
import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { Z_INDEX } from '@/styles/layout';
import { applyBreakpointPadding, marginX, marginY, mediaBreakpointUp, paddingX, paddingY } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import styled, { css } from 'styled-components';
import constants from './constants';

const BORDER_COLOR = '#BDC9D6';
const { PRIMARY_NAV_HEIGHT } = constants;

interface NavigationElementProps {
  isTransparent: boolean;
  fixed: boolean;
  inverted: boolean;
}

const buttonCss = css`
  ${PrimaryDefaultButton} {
    --button-color: var(--nav-primary-color);
    --button-text-color: var(--nav-secondary-color);
    --button-color-hover: ${COLORS.RED.LOWLIGHT};
    --button-glow-color: ${COLORS.RED.LOWLIGHT};
    --button-text-color-hover: ${COLORS.WHITE};
  }

  ${SecondaryDefaultButton} {
    --button-color: var(--nav-primary-color);
    --button-text-color: var(--nav-primary-color);
    --button-color-hover: ${COLORS.RED.LOWLIGHT};
    --button-glow-color: ${COLORS.RED.LOWLIGHT};
    --button-text-color-hover: ${COLORS.WHITE};
  }
`;

const Navigation = styled.nav<NavigationElementProps>`
  position: ${(props) => (props.fixed ? 'fixed' : 'sticky')};
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.PRIMARY_NAV};
  width: 100%;
  background-color: ${(props) => (props.isTransparent ? 'transparent' : COLORS.WHITE)};
  box-shadow: rgb(0 27 56 / ${(props) => (props.isTransparent ? '0%' : '8%')}) 0 4px 16px;
  transition: background-color ${(props) => (props.fixed ? '0s' : '0.3s')} ease-in-out,
    box-shadow ${(props) => (props.fixed ? '0s' : '0.3s')} ease-in-out;

  --nav-primary-color: ${(props) => (props.inverted && props.isTransparent ? COLORS.WHITE : COLORS.BLACK)};
  --nav-secondary-color: ${(props) => (props.inverted && props.isTransparent ? COLORS.BLACK : COLORS.WHITE)};
`;

const NavigationContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  height: ${remCalc(PRIMARY_NAV_HEIGHT)};

  ${mediaBreakpointUp('desktop')} {
    padding: ${remCalc(0, 40)};
    gap: 32px;
  }

  @media (min-width: ${remCalc(1080)}) {
    gap: 40px;
  }
`;

const LogoLink = styled.a`
  display: inline-block;
  height: ${remCalc(26)};

  div,
  svg {
    height: 100%;
  }

  svg {
    [fill='#061B36'] {
      fill: var(--nav-primary-color);
      transition: fill 0.15s ease-in-out;
    }
  }

  .icon {
    display: none;
  }

  ${mediaBreakpointUp('desktop')} {
    height: ${remCalc(36)};

    .icon {
      display: block;
    }

    .full {
      display: none;
    }
  }

  @media (min-width: ${remCalc(1170)}) {
    .icon {
      display: none;
    }

    .full {
      display: block;
    }
  }
`;

const MobileNavigation = styled.nav<{ isVisible: boolean }>`
  ${applyBreakpointPadding};

  position: fixed;
  top: ${remCalc(PRIMARY_NAV_HEIGHT)};
  left: 0;
  width: 100%;
  height: calc(100vh - ${remCalc(PRIMARY_NAV_HEIGHT)});
  padding-bottom: 2rem;
  overflow-y: auto;
  background-color: ${COLORS.WHITE};
  z-index: 200;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};

  ${mediaBreakpointUp('desktop')} {
    display: none;
  }
`;

const HamburgerWrapper = styled.div`
  ${marginX('auto', -12)}
  color: var(--nav-primary-color);

  ${mediaBreakpointUp('desktop')} {
    display: none;
  }
`;

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${Navigation} & {
    display: none;
  }

  ${mediaBreakpointUp('desktop')} {
    flex-direction: row;
    gap: inherit;

    ${Navigation} & {
      display: flex;
    }
  }
`;

const LinkListItem = styled.li`
  border-bottom: 1px solid ${BORDER_COLOR};
  white-space: nowrap;

  ${LinkList} &:last-of-type {
    border-bottom: none;
  }

  ${PrimaryButton} {
    ${marginY(16)}
  }

  ${mediaBreakpointUp('desktop')} {
    border-bottom: none;

    height: ${remCalc(26)};
  }
`;

const LinkStyle = css`
  ${customTextSize(16, 26)}
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  color: var(--nav-primary-color);
  transition: color 0.15s ease-in-out;
`;

const ButtonWrapper = styled.div<{ hideOnMobile: boolean }>`
  display: ${(props) => (props.hideOnMobile ? 'none' : 'flex')};
  margin: 0 0 0 auto;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  * {
    white-space: nowrap;
  }

  ${buttonCss};

  ${mediaBreakpointUp('desktop')} {
    display: flex;
  }
`;

const StandaloneNavLink = styled.a`
  ${LinkStyle};
  ${paddingX(12)}

  &:hover {
    text-decoration: underline;
  }
`;

const LinkListAnchor = styled.a`
  ${LinkStyle};

  align-items: center;
  cursor: pointer;
  display: inline-flex;
  width: 100%;

  span {
    position: relative;
  }

  ${LinkListItem} & {
    ${paddingY(16)};
  }

  ${mediaBreakpointUp('desktop')} {
    span {
      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: ${remCalc(-27)};
        width: 100%;
        display: block;
      }

      /* Whitespace under link */
      &::before {
        height: ${remCalc(27)};
      }

      /* Underline */
      &::after {
        height: ${remCalc(2)};
        background-color: ${COLORS.BLACK};
        opacity: 0;

        transition: opacity 0.15s ease-in-out;
      }
    }

    ${LinkListItem} & {
      ${paddingY(0)};

      &:hover {
        span::after {
          opacity: 1;
        }
      }
    }

    ${LinkListItem}:hover & {
      span::after {
        opacity: 1;
      }
    }
  }
`;

const Submenu = styled.div<{ isVisible: boolean; pushLeft: boolean; disablePointerEvents: boolean }>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};

  background-color: ${COLORS.WHITE};

  ${mediaBreakpointUp('desktop')} {
    --left-margin: var(--breakpoint-padding-x, 0);

    display: flex;
    flex-direction: row;
    position: absolute;
    top: ${remCalc(PRIMARY_NAV_HEIGHT)};

    ${(props) =>
      props.pushLeft
        ? css`
            left: var(--left-margin);
          `
        : css`
            transform: translateX(${remCalc(-40)});
          `}

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
    box-shadow: 0 0 ${remCalc(20)} ${hexToRGBA(COLORS.BLACK, 0.1)};
    padding: ${remCalc(40)};
    border-radius: ${remCalc(10)};
    max-width: min(${remCalc(MAX_CONTAINER_WIDTH)}, calc(100% - (var(--breakpoint-padding-x) * 2)));

    ${LinkListItem}:hover & {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }
  }

  ${(props) =>
    props.disablePointerEvents &&
    css`
      pointer-events: none !important;
    `}
`;

const SubmenuCaret = styled.div<{ flip: boolean; isVisible: boolean }>`
  ${customTextSize(0, 0)}
  display: ${(props) => (props.isVisible ? 'inline-block' : 'none')};
  margin: 0 0 0 auto;
  transform: rotateX(${(props) => (props.flip ? 0 : 180)}deg);

  ${mediaBreakpointUp('desktop')} {
    display: none;
  }
`;

const CategoryColumn = styled.div<{ columns: number }>`
  border-bottom: 1px solid ${BORDER_COLOR};

  ${Submenu} &:last-of-type {
    border-bottom: none;
  }

  ${mediaBreakpointUp('desktop')} {
    border-bottom: none;

    box-sizing: content-box;

    margin-right: ${remCalc(40)};
    padding-right: ${remCalc(40)};
    border-right: 1px solid ${BORDER_COLOR};

    flex: 1 1 ${(props) => props.columns}fr;

    ${Submenu} &:last-of-type {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }
`;

const CategoryEyebrow = styled.p`
  ${customTextSize(12, 19)}
  ${marginY(30)}

  color: ${COLORS.RED.LOWLIGHT};
  text-transform: uppercase;
  font-weight: ${FONT_WEIGHTS.BOLD};
  letter-spacing: ${remCalc(1.2)};

  ${mediaBreakpointUp('desktop')} {
    margin-top: 0;
    margin-bottom: ${remCalc(30)};
  }
`;

const SubmenuList = styled.ul<{ rows: number }>`
  margin: 0 ${remCalc(-16)};

  ${mediaBreakpointUp('desktop')} {
    display: grid;
    grid-template-rows: repeat(${(props) => props.rows}, auto);
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    row-gap: 0.5rem;
  }
`;

const SubmenuItem = styled.li`
  white-space: normal;

  a {
    display: block;
    height: 100%;

    background-color: transparent;
    padding: ${remCalc(16)};
    border-radius: ${remCalc(10)};

    transition: background-color 0.15s ease-in-out;
  }

  &:hover a {
    background-color: ${hexToRGBA(COLORS.GRAY.HIGHLIGHT, 0.5)};
  }

  ${mediaBreakpointUp('desktop')} {
    width: 100%;
    max-width: ${remCalc(300)};
  }
`;

const SubmenuItemInner = styled.div`
  display: flex;
  align-items: flex-start;

  p {
    ${customTextSize(14, 20)}
  }

  ${mediaBreakpointUp('desktop')} {
    ${CategoryColumn} ${SubmenuItem}:last-child & {
      margin-bottom: 0;
    }

    p {
      ${customTextSize(12, 16)}
    }
  }
`;

const SubmenuItemTitle = styled.span`
  ${customTextSize(16, 20)}

  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  display: block;
  margin-bottom: ${remCalc(4)};
`;

const MobileCTAListItem = styled.li`
  width: 100%;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    width: 100%;
  }
`;

const primaryNavComponents = {
  ButtonWrapper,
  CategoryColumn,
  CategoryEyebrow,
  HamburgerWrapper,
  LinkList,
  LinkListAnchor,
  LinkListItem,
  LinkStyle,
  LogoLink,
  MobileCTAListItem,
  MobileNavigation,
  Navigation,
  NavigationContainer,
  StandaloneNavLink,
  Submenu,
  SubmenuCaret,
  SubmenuItem,
  SubmenuItemInner,
  SubmenuItemTitle,
  SubmenuList,
};

export default primaryNavComponents;
