import React from 'react';

const useTimeout = (callback: () => void, delay: number | null) => {
  const timeoutRef = React.useRef<number | null>(null);
  const savedCallback = React.useRef(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(tick, delay);

      return () => window.clearTimeout(timeoutRef.current!);
    }
  }, [delay]);

  return timeoutRef;
};

export default useTimeout;
