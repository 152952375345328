import IconLogo from '@/components/_rebrand/IconLogo';
import InputField from '@/components/forms/InputField';
import PhoneField from '@/components/forms/PhoneField';
import SelectField from '@/components/forms/SelectField';
import Shared from '@/components/modals/shared';
import { GTM_VARIABLES } from '@/constants/gtm';
import {
  HubspotCompanySizeDropdownOptions,
  HubspotFieldLabels,
  HubspotFieldNames,
  HubspotFieldValidators,
  HubspotFormIDs,
  HubspotIndustryDropdownOptions,
  HubspotPrimaryUsecaseDropdownOptions,
} from '@/constants/hubspot';
import { OPTIMIZELY_EVENTS } from '@/constants/optimizely';
import { UserContext } from '@/context/user';
import { useCustomFormik, useFormikHubspotSubmit } from '@/lib/formik';
import gtm from '@/lib/gtm';
import { checkHubspotEmailDomain } from '@/lib/hubspot';
import optimizely from '@/lib/optimizely';
import { isWorkEmail } from '@/lib/validation';
import { FC, useContext, useEffect } from 'react';

export interface FormStepFields {
  [HubspotFieldNames.CompanyName]: string;
  [HubspotFieldNames.CompanySize]: string;
  [HubspotFieldNames.Email]: string;
  [HubspotFieldNames.FirstName]: string;
  [HubspotFieldNames.Industry]: string;
  [HubspotFieldNames.JobTitle]: string;
  [HubspotFieldNames.LastName]: string;
  [HubspotFieldNames.Phone]: string;
  [HubspotFieldNames.PrimaryUsecase]: string;
}

interface FormStepProps {
  onSuccess: (values: FormStepFields, showChiliPiper: boolean) => void;
}

const FormStep: FC<FormStepProps> = ({ onSuccess }) => {
  const { getTempUserData } = useContext(UserContext);

  const formik = useCustomFormik<FormStepFields>({
    initialValues: {
      [HubspotFieldNames.CompanyName]: '',
      [HubspotFieldNames.CompanySize]: '',
      [HubspotFieldNames.Email]: '',
      [HubspotFieldNames.FirstName]: '',
      [HubspotFieldNames.Industry]: '',
      [HubspotFieldNames.JobTitle]: '',
      [HubspotFieldNames.LastName]: '',
      [HubspotFieldNames.Phone]: '',
      [HubspotFieldNames.PrimaryUsecase]: '',
    },
    onSubmit: useFormikHubspotSubmit({
      formId: HubspotFormIDs.RequestDemo,
      name: 'Request Demo Modal',
      content: 'Request Demo Modal',
      onSuccess: async (values) => {
        gtm.trackDemoRequest({
          [GTM_VARIABLES.USER_EMAIL]: values[HubspotFieldNames.Email],
          [GTM_VARIABLES.USER_FIRST_NAME]: values[HubspotFieldNames.FirstName],
          [GTM_VARIABLES.USER_LAST_NAME]: values[HubspotFieldNames.LastName],
          [GTM_VARIABLES.USER_PHONE]: values[HubspotFieldNames.Phone],
        });

        optimizely.event(OPTIMIZELY_EVENTS.ANY_GET_STARTED_MODAL_FORM_SUBMIT);
        optimizely.event(OPTIMIZELY_EVENTS.REQUEST_DEMO_SUCCESS);

        const { isPayingCustomer } = await checkHubspotEmailDomain(values[HubspotFieldNames.Email]);

        onSuccess(values, isPayingCustomer);
      },
      onError: () => {
        //
      },
    }),
    validate: (values) => {
      const errors = {};

      errors[HubspotFieldNames.Email] = HubspotFieldValidators[HubspotFieldNames.Email](
        values[HubspotFieldNames.Email],
        {
          required: true,
          allowPersonalEmail: true,
          allowSharedEmail: true,
        },
      );

      errors[HubspotFieldNames.FirstName] = HubspotFieldValidators[HubspotFieldNames.FirstName](
        values[HubspotFieldNames.FirstName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.LastName] = HubspotFieldValidators[HubspotFieldNames.LastName](
        values[HubspotFieldNames.LastName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.CompanyName] = HubspotFieldValidators[HubspotFieldNames.CompanyName](
        values[HubspotFieldNames.CompanyName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.Industry] = HubspotFieldValidators[HubspotFieldNames.Industry](
        values[HubspotFieldNames.Industry],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.CompanySize] = HubspotFieldValidators[HubspotFieldNames.CompanySize](
        values[HubspotFieldNames.CompanySize],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.JobTitle] = HubspotFieldValidators[HubspotFieldNames.JobTitle](
        values[HubspotFieldNames.JobTitle],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.Phone] = HubspotFieldValidators[HubspotFieldNames.Phone](
        values[HubspotFieldNames.Phone],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.PrimaryUsecase] = HubspotFieldValidators[HubspotFieldNames.PrimaryUsecase](
        values[HubspotFieldNames.PrimaryUsecase],
        {
          required: true,
        },
      );

      if (Object.values(errors).every((val) => val === undefined)) return;

      return errors;
    },
    validateOnBlur: false,
  });

  useEffect(() => {
    const data = getTempUserData();

    Object.keys(data).forEach((key) => {
      formik.setFieldValue(key, data[key]);
    });
  }, []);

  const isNotWorkEmail = !isWorkEmail(formik.values[HubspotFieldNames.Email]);

  return (
    <>
      <IconLogo width={75} height={25} />
      <h2>Get a personalized demo</h2>
      <p>Help us tailor the demo experience to your needs.</p>

      <form onSubmit={formik.handleSubmit} noValidate>
        <InputField
          type="email"
          name={HubspotFieldNames.Email}
          label={HubspotFieldLabels.Email}
          placeholder="you@company.com"
          value={formik.values[HubspotFieldNames.Email]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik.touched[HubspotFieldNames.Email] && !!formik.errors[HubspotFieldNames.Email]}
          warningMessage={isNotWorkEmail ? "It's best to sign up with your work email" : undefined}
          errorMessage={formik.errors[HubspotFieldNames.Email] as string}
        />

        <fieldset>
          <InputField
            type="text"
            name={HubspotFieldNames.FirstName}
            label={HubspotFieldLabels.FirstName}
            placeholder="Jamie"
            value={formik.values[HubspotFieldNames.FirstName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.FirstName] && !!formik.errors[HubspotFieldNames.FirstName]}
            errorMessage={formik.errors[HubspotFieldNames.FirstName] as string}
            translucent
          />

          <InputField
            type="text"
            name={HubspotFieldNames.LastName}
            label={HubspotFieldLabels.LastName}
            placeholder="Smith"
            value={formik.values[HubspotFieldNames.LastName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.LastName] && !!formik.errors[HubspotFieldNames.LastName]}
            errorMessage={formik.errors[HubspotFieldNames.LastName] as string}
            translucent
          />
        </fieldset>

        <fieldset>
          <InputField
            type="text"
            name={HubspotFieldNames.CompanyName}
            label={HubspotFieldLabels.CompanyName}
            placeholder="Your Company"
            value={formik.values[HubspotFieldNames.CompanyName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.CompanyName] && !!formik.errors[HubspotFieldNames.CompanyName]}
            errorMessage={formik.errors[HubspotFieldNames.CompanyName] as string}
            translucent
          />

          <InputField
            type="text"
            name={HubspotFieldNames.JobTitle}
            label={HubspotFieldLabels.JobTitle}
            placeholder="e.g., Director of Operations"
            value={formik.values[HubspotFieldNames.JobTitle]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.JobTitle] && !!formik.errors[HubspotFieldNames.JobTitle]}
            errorMessage={formik.errors[HubspotFieldNames.JobTitle] as string}
            translucent
          />
        </fieldset>

        <fieldset>
          <SelectField
            name={HubspotFieldNames.CompanySize}
            label={HubspotFieldLabels.CompanySize}
            options={HubspotCompanySizeDropdownOptions}
            value={formik.values[HubspotFieldNames.CompanySize]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.CompanySize] && !!formik.errors[HubspotFieldNames.CompanySize]}
            errorMessage={formik.errors[HubspotFieldNames.CompanySize] as string}
            translucent
          />

          <SelectField
            name={HubspotFieldNames.Industry}
            label={HubspotFieldLabels.Industry}
            options={HubspotIndustryDropdownOptions}
            value={formik.values[HubspotFieldNames.Industry]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.Industry] && !!formik.errors[HubspotFieldNames.Industry]}
            errorMessage={formik.errors[HubspotFieldNames.Industry] as string}
            translucent
          />
        </fieldset>

        <fieldset>
          <PhoneField
            name={HubspotFieldNames.Phone}
            label={HubspotFieldLabels.Phone}
            value={formik.values[HubspotFieldNames.Phone]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.Phone] && !!formik.errors[HubspotFieldNames.Phone]}
            errorMessage={formik.errors[HubspotFieldNames.Phone] as string}
          />

          <SelectField
            name={HubspotFieldNames.PrimaryUsecase}
            label={HubspotFieldLabels.PrimaryUsecase}
            options={HubspotPrimaryUsecaseDropdownOptions}
            value={formik.values[HubspotFieldNames.PrimaryUsecase]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.PrimaryUsecase] && !!formik.errors[HubspotFieldNames.PrimaryUsecase]}
            errorMessage={formik.errors[HubspotFieldNames.PrimaryUsecase] as string}
            translucent
          />
        </fieldset>

        <input type="submit" style={{ display: 'none' }} />
      </form>

      <Shared.Footer>
        <span>{/* Leave empty */}</span>
        <div>
          <Shared.StyledPrimaryButton
            type="button"
            disabled={formik.isValidating || formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {formik.isSubmitting ? 'Submitting...' : 'Submit'}
          </Shared.StyledPrimaryButton>
        </div>
      </Shared.Footer>
    </>
  );
};

export default FormStep;
